import styled from '@emotion/styled';
import React, { useState } from 'react';
import { style } from '../../../components/Shared/Style';
import { colors } from '../../../components/Shared/Style/colors';
import { L } from '../../../lib/i18n';
import AddNewMemberToProperty from './AddNewMembershipToProperty';
import AddNewMemberToProject from './AddNewMemberToProject';

const Span = styled.div(({ theme }) => ({
    fontSize: style.fontSize.seta,
    display: 'inline-block',
    fontWeight: style.fontWeight.bold,
    color: theme.colors.secondary,
    '&.active': {
        color: theme.colors.primary,
    },
    '&:hover': {
        cursor: 'pointer',
    },
}));

const FormWrapper = styled.div({
    width: '400px',
    placeSelf: 'center',
    backgroundColor: colors.white,
    padding: '2rem',
    minHeight: '600px',
    position: 'relative',
    borderRadius: style.roundCornerSize.small,
});

const FlexWrapper = styled.div({
    display: 'flex',
    flexDirection: 'row',
    gap: '0.5rem',
    marginTop: style.margin.ml,
});

const SpanDivider = styled.div({
    borderRight: '1px solid grey',
});

const FormTitle = styled.div({
    fontSize: '1.2rem',
    fontWeight: 'bold',
});

type Props = {
    handleModal: () => void;
};

const AddNewMembershipForm = ({ handleModal }: Props) => {
    const [view, setView] = useState<'project' | 'property'>('project');

    return (
        <>
            <FormWrapper onClick={(e) => e.stopPropagation()}>
                <FormTitle>{L('add_to_membership')}</FormTitle>
                <FlexWrapper>
                    <Span
                        className={view === 'project' ? 'active' : ''}
                        onClick={() => {
                            setView('project');
                        }}
                    >
                        {L('project')}
                    </Span>
                    <SpanDivider />
                    <Span
                        className={view === 'property' ? 'active' : ''}
                        onClick={() => {
                            setView('property');
                        }}
                    >
                        {L('property')}
                    </Span>
                </FlexWrapper>
                {view === 'project' && <AddNewMemberToProject handleModal={handleModal} />}
                {view === 'property' && <AddNewMemberToProperty handleModal={handleModal} />}
            </FormWrapper>
        </>
    );
};

export default AddNewMembershipForm;
