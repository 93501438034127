import * as React from 'react';
import styled from '@emotion/styled';
import { User } from '@app/api/models/Users';
import { style } from '../Shared/Style';
import AvatarIcon from '../thread/avatar/Avatar';
import { useState } from 'react';
import { useSpring, animated } from 'react-spring';
import Close from '../SliderMenu/Close/Close';
import useGetProfile from '../../hooks/useGetProfile';
import useGetProfileImage from '../../hooks/useGetProfileImage';
import { trpc } from '../../lib/trpc';

const Container = styled.div({
    backgroundColor: 'inherit',
    borderRadius: style.roundCornerSize.small,
    display: 'flex',
    marginLeft: 'auto',
    zIndex: 100,
});

const ProfileSideMenu = animated(
    styled.div({
        position: 'absolute',
        right: '0',
        top: '0',
        backgroundColor: '#F7F8FC',
        width: '300px',
        boxShadow: '0 0 35px gray',
        color: 'black',
    })
);

const ProfileMenuWrapper = styled.div({
    margin: '30px auto 0px auto',
});

const ProfileMenuActions = styled.div({
    margin: '20px 0',
});

const ProfileMenuAction = styled.div({
    width: '100%',
    padding: '10px 0px',
    fontSize: '1rem',
    cursor: 'pointer',
    textAlign: 'center',
    fontWeight: 300,
    margin: 'auto',
});

const ProfilePicture = styled.img({
    margin: '10px auto',
    width: '100px',
    height: '100px',
    borderRadius: '50%',
});

const SmallAvatarIcon = styled(AvatarIcon)({
    cursor: 'pointer',
});

const BigAvatarIcon = styled(AvatarIcon)({
    width: '100px',
    height: '100px',
    fontSize: '3rem',
    margin: 'auto',
});

const UserInfo = styled.div({
    textAlign: 'center',
    marginTop: '1rem',
});

const UserName = styled.div({
    margin: 'auto',
    width: '90%',
});

const UserMail = styled(UserName)({
    wordBreak: 'break-word',
});

const StyledHr = styled.hr({
    width: '85%',
    margin: '0px auto',
});

const StyledClose = styled(Close)({
    fontWeight: 100,
});

export const Avatar = () => {
    const { data: user } = trpc.AuthApi.getCurrentUser.useQuery();
    const { mutate } = trpc.AuthApi.logout.useMutation();

    const handleLogout = async () => {
        mutate(undefined, {
            onSuccess: () => {
                window.location.reload();
            },
        });
    };
    const [profileMenuOpen, setProfileMenuOpen] = useState(false);
    const slideIn = useSpring({
        transform: profileMenuOpen ? 'translateX(0px)' : 'translateX(300px)',
        config: { friction: 30 },
    });
    if (!user) return null;
    return (
        <Container>
            <SmallAvatarIcon onClick={() => setProfileMenuOpen(!profileMenuOpen)} name={user.email} />

            {profileMenuOpen && (
                <ProfileSideMenu style={slideIn}>
                    <StyledClose inverted onClose={() => setProfileMenuOpen(false)} />
                    <ProfileMenuWrapper>
                        <BigAvatarIcon name={user.email} />
                        <UserInfo>
                            <UserMail>{user.email}</UserMail>
                        </UserInfo>
                        <ProfileMenuActions>
                            <StyledHr />
                            <ProfileMenuAction>Profilsida</ProfileMenuAction>
                            <StyledHr />
                            <ProfileMenuAction onClick={handleLogout}>Logga ut</ProfileMenuAction>
                            <StyledHr />
                        </ProfileMenuActions>
                    </ProfileMenuWrapper>
                </ProfileSideMenu>
            )}
        </Container>
    );
};
