import { createTRPCReact } from '@trpc/react-query';
import type { AppRouter } from '@app/api/index';
export const trpc = createTRPCReact<AppRouter>();
import { createTRPCProxyClient, httpBatchLink } from '@trpc/client';
export const client = createTRPCProxyClient<AppRouter>({
    links: [
        httpBatchLink({
            url: '/trpc',
            fetch(url, options) {
                return fetch(url, { ...options, credentials: 'include' });
            },
        }),
    ],
});
