import * as React from 'react';
import { L } from '../../lib/i18n';
import PageTitle from '../../components/typography/PageTitle';
import { Route, Switch } from 'react-router-dom';
import MemberShipCheckPage from './memberships/MembershipCheckPage';
import ModalProvider from '../../components/Modal/Context/ModalContext';
import MemberDetailPage from './DetailPage/MemberDetailPage';

export const MembershipPage = () => {
    return (
        <>
            <PageTitle>{L('Användare')}</PageTitle>
            <Switch>
                <Route exact path={'/membership'}>
                    <ModalProvider>
                        <MemberShipCheckPage />
                    </ModalProvider>
                </Route>
                <Route path={'/membership/:id'}>
                    <ModalProvider>
                        <MemberDetailPage />
                    </ModalProvider>
                </Route>
            </Switch>
        </>
    );
};
