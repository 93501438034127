import styled from '../../../lib/styled';
import React from 'react';
import { FC } from 'react';
import { colors } from '../../Shared/Style/colors';
import Icon from '../attachment.svg';

const ThemedDocument = styled(Icon)(() => {
    return {
        height: '24px',
        width: '24px',
        display: 'block',
        transform: 'translate(-3px, 0)',
        '& path': {
            stroke: colors.icons,
        },
    };
});

const Document: FC = ({ ...rest }) => {
    return <ThemedDocument viewBox={'0 0 48 48'} {...rest} />;
};

export default Document;
