export const communityOptions = [
    { label: 'Avtal', value: '/contracts' },
    { label: 'Bokning', value: '/booking' },
    { label: 'Event', value: '/event' },
    { label: 'Förening', value: '/project' },
    { label: 'Hemsida', value: '/public-site' },
    { label: 'Hjälp', value: '/help' },
    { label: 'Information', value: '/information' },
    { label: 'Inställningar', value: '/settings' },
    { label: 'Leverantör', value: '/service-partner' },
    { label: 'Kontakter', value: '/contacts' },
    { label: 'Medlemmar', value: '/members' },
    { label: 'Mejl', value: '/mail' },
    { label: 'Nyheter', value: '/news' },
    { label: 'OurLiving Nyheter', value: '/' },
    { label: 'Planerat underhåll', value: '/maintenance' },
    { label: 'Planerat Underhåll/Lägg Till', value: '/maintenance/add' },
    { label: 'Properties', value: '/properties' },
    { label: 'Styrelse Event', value: '/board-event' },
    { label: 'Styrelse Information', value: '/board-information' },
    { label: 'Styrelse Nyheter', value: '/board-news' },
    { label: 'Styrelsemedlemmar', value: '/boardmembers' },
    { label: 'Ärenden', value: '/tickets' },
];

export const residenceOptions = [
    { label: 'Tillval', value: '/properties/:id/addons' },
    { label: 'Felanmälan', value: '/properties/:id/ticket/new' },
    { label: 'Att göra', value: '/properties/:id/todo/new' },
    { label: 'Redigera rum', value: '/properties/:id/binder/space/:id/edit' },
    { label: 'Redigera produkt', value: '/properties/:id/binder/space/:id/product/:id/edit' },
    { label: 'Login problem', value: '/loginproblem' },
];

export const propertyManagementOptions = [{ label: 'Dashboard', value: '/' }];

export const applicationsOptions = [
    { label: 'Community', value: 'community' },
    { label: 'Residence', value: 'residence' },
    { label: 'Property management', value: 'property_management' },
];
