import React from 'react';
import { Switch } from 'react-router-dom';
import { PageWithNavigation } from './components/Layout/Page';
import ErrorNoticehandler from './components/notice/ErrorNoticehandler';
import * as Blog from './areas/blog/navigation';
import * as Help from './areas/help/navigation';
import * as Jobs from './areas/Jobs/navigation';
import * as Settings from './areas/settings/navigation';
import * as MailTemplates from './areas/mail-templates/navigation';
import * as Signing from './areas/signing/navigation';
import * as Users from './areas/members/navigation';
import * as Properties from './areas/properties/navigation';

const PrivateRoutes = () => {
    return (
        <ErrorNoticehandler>
            <PageWithNavigation>
                <Switch>
                    {Blog.routes}
                    {Help.routes}
                    {Jobs.routes}
                    {MailTemplates.routes}
                    {Settings.routes}
                    {Signing.routes}
                    {Users.routes}
                    {Properties.routes}
                </Switch>
            </PageWithNavigation>
        </ErrorNoticehandler>
    );
};

export default PrivateRoutes;

export const navigation = [
    ...Blog.navigation,
    ...Help.navigation,
    ...Jobs.navigation,
    ...MailTemplates.navigation,
    ...Settings.navigation,
    ...Signing.navigation,
    ...Users.navigation,
    ...Properties.navigation,
];
