import React from 'react';
import styled from '@emotion/styled';
import { Switch, Route } from 'react-router-dom';
import SliderMenuProvider from '../../components/SliderMenu/context/SliderMenuContext';
import PageList from './components/PageList';
import AddBlogView from './components/AddBlogView';
import EditBlogItem from './components/EditBlogItem';
import { L } from '../../lib/i18n';
import { Header } from '../../components/Layout/Header';

const GridContainer = styled.div({
    display: 'grid',
    gridTemplateAreas: `
    "PageList Main"
    "ThemeFeatures ThemeFeatures"
    `,
    gap: '32px',
    gridTemplateColumns: '340px 1fr',
});
const Main = styled.main({
    maxWidth: '100%',
});

export const BlogPage = () => {
    return (
        <SliderMenuProvider>
            <div>
                <Header title={L('blog_header')} />
                <GridContainer>
                    <PageList />
                    <Main>
                        <Switch>
                            <Route exact path="/blog/add">
                                <AddBlogView />
                            </Route>
                            <Route exact path="/blog/:id">
                                <EditBlogItem />
                            </Route>
                        </Switch>
                    </Main>
                </GridContainer>
            </div>
        </SliderMenuProvider>
    );
};
