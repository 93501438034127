import { useMutation, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { L } from '../../../lib/i18n';
import { client } from '../../../lib/trpc';
import { memberQueryKeys } from './useGetUser';

const changeEmail = async (variables: { user_id: number; email: string }) => {
    return client.UsersApi.changeEmail.mutate(variables);
};

const useChangeEmail = () => {
    const queryClient = useQueryClient();
    return useMutation((variables: { user_id: number; email: string }) => changeEmail(variables), {
        onError: () => {
            toast.error(L('email_already_exist'));
        },
        onSettled: () => {
            queryClient.invalidateQueries(memberQueryKeys.lists());
        },
    });
};

export default useChangeEmail;
