import React from 'react';
import AddMember from '../job/addMember/AddMember';
import AreaCards from '../../../components/AreaCard/AreaCards/AreaCards';
import AreaCard from '../../../components/AreaCard/AreaCard';
import { Icon } from '../../../components/Icon/Icon';
import { Switch, Route } from 'react-router-dom';
import InviteMember from '../job/inviteMember/InviteMember';

const JobsCreatePage = () => {
    return (
        <div>
            <AreaCards>
                <AreaCard
                    name="Lägg till medlem"
                    to={'/jobs/create/add-member'}
                    description={'Lägger till en medlem på ett projekt'}
                    Icon={<Icon.Member />}
                />
                <AreaCard
                    name="Bjud in medlem"
                    to={'/jobs/create/invite-member'}
                    description={'Skickar inbjudnings mail till medlem'}
                    Icon={<Icon.Messages />}
                />
            </AreaCards>

            <Switch>
                <Route path={'/jobs/create/add-member'}>
                    <AddMember />
                </Route>
                <Route path={'/jobs/create/invite-member'}>
                    <InviteMember />
                </Route>
            </Switch>
        </div>
    );
};

export default JobsCreatePage;
