import React from 'react';
import { StyleColumn } from '../../../types/ColumnType';
import styled from '@emotion/styled';
import { colors } from '../../../components/Shared/Style/colors';
import { Link } from 'react-router-dom';
import { margin } from '../../../components/Shared/Style/margin';

const Span = styled.span({
    textAlign: 'left',
    fontSize: '14px',
});
const StyledNavLink = styled(Link)({
    textDecoration: 'none',
    color: 'inherit',
});

const StyledP = styled.p({
    color: colors.black80,
    marginTop: margin.s,
    fontSize: '12px',
});
export const MembershipsColumns: StyleColumn<any>[] = [
    {
        Header: 'Namn och mail',
        accessor: (value) => [value.email, value.name],
        width: 175,
        style: {
            justifyContent: 'left',
            alignItems: 'center',
        },
        Cell: ({ row }) => {
            const member = row.original;
            return (
                <StyledNavLink to={`/membership/${member.id}`}>
                    <Span>{member.email}</Span>
                    <StyledP>{member.name}</StyledP>
                </StyledNavLink>
            );
        },
    },
];
