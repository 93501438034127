import { Route } from 'react-router-dom';
import React from 'react';
import { L } from '../../lib/i18n';
import { MailTemplatesPage } from './MailTempaltesPage';

export const routes = [<Route key="0" exact path="/mail-templates" component={MailTemplatesPage} />];

export const navigation = [
    { name: L('mail_templates'), link: '/mail-templates', icon: 'Mail', parent: 'BoardMembers' },
];
