import React from 'react';
import { StyleColumn } from '../../../types/ColumnType';
import { L } from '../../../lib/i18n';
import StyledRadixSwitch from '../../../components/StyledSwitch/StyledRadixSwitch';
import { usePublishProperty } from '../hooks/usePublishProperty';
import { useParams } from 'react-router';
import dayjs from 'dayjs';

type Props = {
    user_id: number;
    property_id: number;
    name: string;
    role: string;
    properties: string[];
};

export const COLUMNSPROPERTIES: StyleColumn<any>[] = [
    {
        Header: () => L('property'),
        accessor: 'name',
        width: 150,
        Cell: ({ value }) => {
            return <span>{value}</span>;
        },
    },
    {
        Header: () => L('apartment_number'),
        accessor: 'apartment_number',
        width: 150,
        Cell: ({ value }) => {
            return <span>{value}</span>;
        },
    },
    {
        Header: () => L('address'),
        accessor: 'address',
        width: 150,
        Cell: ({ value }) => {
            return (
                <div>
                    <span style={{ display: 'block' }}>{value.street}</span>
                    <p>{value.city}</p>
                </div>
            );
        },
    },
    {
        Header: () => L('published_at'),
        accessor: 'published_at',
        width: 75,
        Cell: ({ value }) => {
            return <span>{value && dayjs(value).format('YYYY-MM-DD HH:mm')}</span>;
        },
    },
    {
        Header: () => L('published'),
        id: 'property',
        accessor: (value) => [value.published_at, value.propertyId],
        width: 75,
        style: {
            justifyContent: 'left',
            alignItems: 'center',
        },
        Cell: ({ row }) => {
            const { id } = useParams<{ id: string }>();
            const { mutate: togglePublish } = usePublishProperty();
            const property = row.original;
            return (
                <StyledRadixSwitch
                    checked={!!property.published_at}
                    onCheckedChange={() =>
                        togglePublish({
                            propertyId: property.id,
                            propertyName: property.name,
                            publish: !property.published_at,
                            projectId: +id,
                        })
                    }
                />
            );
        },
    },
];
