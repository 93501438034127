import styled from '@emotion/styled';
import React from 'react';
import { useParams } from 'react-router';
import { Button } from '../../../components/Buttons/Button';
import Label from '../../../components/Forms/Label';
import { style } from '../../../components/Shared/Style';
import { colors } from '../../../components/Shared/Style/colors';
import { L } from '../../../lib/i18n';
import { useForm, Controller } from 'react-hook-form';
import toast from 'react-hot-toast';
import { zodResolver } from '@hookform/resolvers/zod';
import useResetPassword from '../hooks/useResetPassword';
import { z } from 'zod';
import ReactSelect from '../../../components/Dropdown/ReactSelect';

const DropDownLabel = styled.div({
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: style.fontSize.milli,
});

const FormWrapper = styled.div({
    width: '400px',
    placeSelf: 'center',
    backgroundColor: colors.white,
    padding: '2rem',
    minHeight: '250px',
    position: 'relative',
    borderRadius: style.roundCornerSize.small,
});

const ButtonWrapper = styled.div({
    padding: '1rem 0px 0px 0px',
    display: 'flex',
    width: '50%',
    right: '2rem',
    justifyContent: 'space-between',
    position: 'absolute',
    bottom: '1rem',
});

const FormTitle = styled.div({
    fontSize: '1.2rem',
    fontWeight: 'bold',
});

type Props = {
    handleModal: () => void;
};

const resetPasswordSchema = z.object({
    app: z.string(),
});

type ResetPasswordSchema = z.infer<typeof resetPasswordSchema>;

const ChangePasswordForm = ({ handleModal }: Props) => {
    const { id } = useParams<{ id: string }>();

    const { mutate: resetPassword } = useResetPassword();
    const { handleSubmit, control, watch } = useForm<ResetPasswordSchema>({
        resolver: zodResolver(resetPasswordSchema),
    });
    const handleChangePassword = (data: { app: string }) => {
        resetPassword(
            { app: data.app, user_id: +id },
            {
                onSuccess: () => {
                    handleModal();
                    toast.success(L('reset_password_mail_sent'));
                },
            }
        );
    };
    const siteOptions = [
        {
            value: 'OURLIVING_COMMUNITY',
            label: (
                <DropDownLabel>
                    <span>{L('community')}</span>
                </DropDownLabel>
            ),
        },
        {
            value: 'OURLIVING_RESIDENCE',
            label: (
                <DropDownLabel>
                    <span>{L('residence')}</span>
                </DropDownLabel>
            ),
        },
    ];
    return (
        <>
            <FormWrapper onClick={(e) => e.stopPropagation()}>
                <FormTitle>{L('reset_password')}</FormTitle>
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        handleSubmit(handleChangePassword)();
                    }}
                >
                    <Label title={`${L('app')}*`}>
                        <Controller
                            name="app"
                            control={control}
                            render={({ field }) => {
                                return (
                                    <ReactSelect
                                        options={siteOptions || []}
                                        value={siteOptions?.find((option) => option.value === field.value)}
                                        onChange={(value) => field.onChange(value?.value || '')}
                                    />
                                );
                            }}
                        />
                    </Label>
                    <ButtonWrapper>
                        <Button type="button" onClick={() => handleModal()} variant="primary">
                            Avbryt
                        </Button>
                        <Button type={'submit'} variant="filledPrimary">
                            {L('save')}
                        </Button>
                    </ButtonWrapper>
                </form>
            </FormWrapper>
        </>
    );
};

export default ChangePasswordForm;
