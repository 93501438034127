import styled from '@emotion/styled';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import Label from '../../../components/Forms/Label';
import toast from 'react-hot-toast';
import { style } from '../../../components/Shared/Style';
import { L } from '../../../lib/i18n';
import { useGetOrganisationProjects, useGetOrganisations } from '../hooks/useGetOrganisations';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { Button } from '../../../components/Buttons/Button';
import ReactSelect from '../../../components/Dropdown/ReactSelect';
import { useAddMemberToProject } from '../hooks/useAddMembership';
import { useParams } from 'react-router';

const DropDownLabel = styled.div({
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: style.fontSize.milli,
});

const ButtonWrapper = styled.div({
    padding: '1rem 0px 0px 0px',
    display: 'flex',
    width: '50%',
    right: '2rem',
    justifyContent: 'space-between',
    position: 'absolute',
    bottom: '1rem',
});

const addMemberToProjectSchema = z.object({
    organisation: z.number(),
    project_id: z.number(),
    role: z.string(),
});

type Props = {
    handleModal: () => void;
};

type AddMemberToProjectSchema = z.infer<typeof addMemberToProjectSchema>;

const AddNewMemberToProject = ({ handleModal }: Props) => {
    const { id } = useParams<{ id: string }>();
    const { data: accounts } = useGetOrganisations();
    const { mutate: addMemberToProject } = useAddMemberToProject();

    const options = accounts?.map((account) => ({
        value: account.id,
        label: (
            <DropDownLabel>
                <span>{account.name}</span>
            </DropDownLabel>
        ),
    }));

    const {
        handleSubmit: projectSubmit,
        control: projectControl,
        watch,
    } = useForm<AddMemberToProjectSchema>({
        resolver: zodResolver(addMemberToProjectSchema),
    });

    const { data: projectList } = useGetOrganisationProjects(watch('organisation'));

    const projectOptions = projectList?.map((project) => ({
        value: project.id,
        label: (
            <DropDownLabel>
                <span>{project.name}</span>
            </DropDownLabel>
        ),
    }));

    const roleOptions = [
        {
            value: 'brf_admin',
            label: (
                <DropDownLabel>
                    <span>{L('brf_admin')}</span>
                </DropDownLabel>
            ),
        },
        {
            value: 'board_member',
            label: (
                <DropDownLabel>
                    <span>{L('board_member')}</span>
                </DropDownLabel>
            ),
        },
    ];

    const handleProjectSubmit = (data: { project_id: number; role: string }) => {
        addMemberToProject(
            {
                project_id: data.project_id,
                user_id: +id,
                role: data.role,
            },
            {
                onSuccess: () => {
                    handleModal();
                    toast.success(L('member_added'));
                },
            }
        );
    };

    return (
        <>
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    projectSubmit(handleProjectSubmit)();
                }}
            >
                <Label title={`${L('organisation')}*`}>
                    <Controller
                        name="organisation"
                        control={projectControl}
                        render={({ field }) => {
                            return (
                                <ReactSelect
                                    options={options || []}
                                    value={options?.find((option) => option.value === field.value)}
                                    onChange={(value) => field.onChange(value?.value || 0)}
                                />
                            );
                        }}
                    />
                </Label>
                <Label title={`${L('project')}*`}>
                    <Controller
                        name="project_id"
                        control={projectControl}
                        render={({ field }) => {
                            return (
                                <ReactSelect
                                    options={projectOptions || []}
                                    value={projectOptions?.find((option) => option.value === field.value)}
                                    onChange={(value) => field.onChange(value?.value || 0)}
                                />
                            );
                        }}
                    />
                </Label>
                <Label title={`${L('role')}`}>
                    <Controller
                        name="role"
                        control={projectControl}
                        render={({ field }) => {
                            return (
                                <ReactSelect
                                    options={roleOptions || []}
                                    value={roleOptions?.find((option) => option.value === field.value)}
                                    onChange={(value) => field.onChange(value?.value || '')}
                                />
                            );
                        }}
                    />
                </Label>
                <ButtonWrapper>
                    <Button type="button" onClick={() => handleModal()} variant="primary">
                        Avbryt
                    </Button>
                    <Button type={'submit'} variant="filledPrimary">
                        {L('save')}
                    </Button>
                </ButtonWrapper>
            </form>
        </>
    );
};

export default AddNewMemberToProject;
