import styled from '@emotion/styled';
import React, { FC } from 'react';

const StyledCardTitle = styled.h2({
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '19px',
    lineHeight: '100%',
    margin: '0px 0px 0px 0px',
});
const CardTitle: FC<React.DetailedHTMLProps<React.HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement>> = ({
    children,
    ...rest
}) => {
    return <StyledCardTitle {...rest}>{children}</StyledCardTitle>;
};

export default CardTitle;
