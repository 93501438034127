import { useQuery } from '@tanstack/react-query';
import { client, trpc } from '../../../lib/trpc';

export const memberQueryKeys = {
    all: [{ scope: 'member' }] as const,
    lists: () => [{ ...memberQueryKeys.all[0], entity: 'list' }] as const,
    list: (email: string) => [{ ...memberQueryKeys.lists()[0], email }] as const,
};

const useGetMemberById = (id: string) => {
    return trpc.UsersApi.getUserById.useQuery(+id, { enabled: !!id });
};

const getUserList = (email: string) => {
    return client.UsersApi.getProjectUser.query(email);
};

const useGetMemberList = (email: string) => {
    return useQuery({
        queryFn: ({ queryKey }) => getUserList(queryKey[0].email),
        queryKey: memberQueryKeys.list(email),
        enabled: !!email,
        keepPreviousData: true,
    });
};

export { useGetMemberList, useGetMemberById };
