import * as React from 'react';
import styled from '@emotion/styled';
import { style } from '../Shared/Style';
import { Header } from '../Header/Header';
import { MenuData } from '../MainMenu/MenuData';

interface BaseCssProps {
    children?: any;
    theme?: any;
}
interface MyProps extends BaseCssProps {
    background?: string;
    title?: string;
}

const PageContainer = styled.div({
    display: 'grid',
    gridTemplateAreas: `
    "logo    header"
    "nav    content"
    `,

    gridTemplateColumns: '220px 1fr',
    gridTemplateRows: '50px auto',
    minHeight: '100vh',
    background: style.colors.pageBackground,
});

const LogoWrapper = styled.div({
    display: 'grid',
    alignContent: 'center',
    paddingLeft: '10px',
    background: style.colors.headerBackground,
});
const Logo = styled.img<MyProps>(() => ({
    height: '40px',
    alignSelf: 'center',
}));

const StyledHeader = styled(Header)({
    paddingRight: '16px',
    paddingLeft: '32px',
});
const Content = styled.div({
    padding: '32px',
});

export function PageWithNavigation(props: { children: React.ReactNode }) {
    return (
        <PageContainer>
            <LogoWrapper>
                <Logo src={require('../../test.png')} />
            </LogoWrapper>
            <StyledHeader />
            <MenuData />
            <Content>{props.children}</Content>
        </PageContainer>
    );
}
