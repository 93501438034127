import React, { ReactNode } from 'react';
import { style } from '../Shared/Style';
import styled from '@emotion/styled';
import H2 from '../typography/H2';
import { NavLink, NavLinkProps } from 'react-router-dom';

const FeatureCard = styled.div(() => ({
    display: 'block',
    width: '100%',
    position: 'relative',
    color: style.colors.black,
    borderRadius: '8px',
    backgroundColor: '#eeeeee',
    height: '100%',
    minHeight: '107px',
    padding: '12px',
}));

const StyledNavlink = styled(NavLink)(() => ({
    textDecoration: 'none',
    display: 'block',

    '&.active > div': {
        backgroundColor: '#F0F1F7',
    },
}));

const Title = styled(H2)({
    padding: '0px',
    margin: '0px',
    fontSize: style.fontSize.seta,
    lineHeight: style.lineHeight.normal,
    fontWeight: style.fontWeight.bold,
});

const Description = styled.p({
    padding: '0px',
    margin: '0px',
    fontSize: style.fontSize.milli,
    lineHeight: style.lineHeight.normal,
    fontWeight: style.fontWeight.thin,
});

const Container = styled.div({
    display: 'flex',
    justifyContent: 'space-between',
});

type Props = {
    className?: string;
    name: string;
    Icon?: ReactNode;
    Switch?: ReactNode;
    description: string | React.ReactElement;
    onClick?: () => void;
    to?: NavLinkProps['to'];
    children?: React.ReactNode;
};

export const AreaCard: React.FC<Props> = ({ className, name, description, onClick, Icon, children, to, Switch }) => {
    if (to) {
        return (
            <StyledNavlink to={to}>
                <FeatureCard className={className} onClick={onClick}>
                    {children}
                    <Container>
                        {Icon ? Icon : <div style={{ height: '30px', width: '30px' }} />}
                        {Switch && Switch}
                    </Container>
                    <Title>{name}</Title>
                    <Description>{description}</Description>
                </FeatureCard>
            </StyledNavlink>
        );
    }
    return (
        <FeatureCard className={className} onClick={onClick}>
            {children}
            <Container>
                {Icon ? Icon : <div style={{ height: '30px', width: '30px' }} />}
                {Switch && Switch}
            </Container>
            <Title>{name}</Title>
            <Description>{description}</Description>
        </FeatureCard>
    );
};
export default AreaCard;
