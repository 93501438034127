export const stringData = {
    name: 'Namn',
    phone_number: 'Tel',
    ticket_new: 'Ny',
    address_googlemaps: 'Adress till Google Maps',
    order_page_success: 'Din beställning är skickad',
    ticket_priority_critical: 'Kritisk',
    ticket_priority_high: 'Hög',
    ticket_priority_medium: 'Normal',
    ticket_priority_low: 'Låg',
    invite_sent: 'Inbjudan skickad',
    features: 'Funktioner',
    features_residence: 'Funktioner för boende (residence)',

    invoice_information: 'Fakturainställningar',
    main_expire_date: 'Huvudförfallodag',
    add: 'Lägg till',
    of: 'av',
    page: 'sida',
    retry_job: 'Kör igen',
    run_job: 'Kör jobb',
    page_size: 'antal',
    Previous: 'Föregående',
    check_user: 'Kontrollera användare',
    check_property: 'Kontrollera bostad',
    Next: 'Nästa',
    paste_html: 'Klistra in din HTML här',
    addon_contract: 'Tillval Avtal',
    addon_order_confirmation: 'Tillval Orderbekräftelse',
    reset_password_request: 'Återställ lösenord',
    confirm_acount: 'Bekräfta konto',
    addon_invite: 'Inbjudan till tillval',
    invite_member_to_property: 'Inbjudan för medlem till bostad',
    ticket_status_new: 'Ny',
    choose_interval: 'Välj intervall',
    interval_from: 'Intervall från',
    template_name: 'Mall',
    template_exists: 'Mall finns',
    interval_to: 'Intervall till',
    font_info: 'Vi använder oss av Google fonts, du kan hitta din favorit font på',
    ticket_status_todo: 'Att göra',
    ticket_status_inprogress: 'Pågår',
    ticket_status_done: 'Klar',
    ticket_status_closed: 'Stängd',
    priority: 'Prioritet',
    status: 'Status',
    year: 'år',
    saved: 'Sparad',
    saving: 'Sparar',
    completed_cost: 'Faktisk kostnad',
    planned_cost: 'Planerad kostnad',
    planned_date: 'Planerat datum',
    interval_end_date: 'Skapa intervall till datum',
    booking_Resource_items: 'Bokningsresurs',
    never_edited: 'Ej ändrad',
    last_edited: 'Senast ändrad',
    assigned_id: 'Tilldelad',
    projects: 'Fastigheter',
    project: 'Fastighet',
    projectOrProperty: 'Projekt/Bostad',
    select_project: 'Välj fastighet',
    property: 'Bostad',
    properties: 'Bostäder',
    tickets: 'Ärenden',
    messages: 'Meddelanden',
    todo: 'Att göra',
    inprogress: 'Pågående',
    assignee: 'Tilldelad',
    filter_by: 'Filtrera på',
    dashboard: 'Dashboard',
    settings: 'Inställningar',
    loading: 'Laddar',
    archive: 'Arkiv',
    country: 'Land',
    city: 'Stad',
    address: 'Adress',
    postalCode: 'Postnummer',
    backtotickets: 'Tillbaka till aktuella ärenden',
    backtorealestates: 'Tillbaka till Fastigheter',
    allrealestates: 'Alla Fastigheter',
    loading_tickets: 'Laddar ärenden',
    new_ticket: 'Nytt ärende',
    created: 'Skapad',
    create_interval_of: 'Skapa intervall av',
    changed: 'Ändrad',
    item: 'Inventarie',
    space: 'Utrymme',
    event_log: 'Logg',
    internal_communication: 'Internkommunikation',
    customer_communication: 'Kundkommunikation',
    cancel: 'Avbryt',
    save: 'Spara',
    save_and_invite: 'Spara och bjud in medlemmar',
    new_message: 'Nytt meddelande',
    message: 'Meddelande',
    login_failed: 'Inloggning misslyckades',
    login_error: 'Tekniskt fel vid Inloggning',
    email: 'E-postadress',
    enter_email: 'Ange e-postadress',
    password: 'Lösenord',
    enter_password: 'Ange lösenord',
    change_password: 'Ändra lösenord',
    send_reset_password: 'Återställningslänk har nu skickats till ',
    delete_user_from_membership: 'Är du säker på att du vill ta bort medlem från ',
    no_memberships_title: 'Det finns inga medlemskap',
    no_memberships_description: 'Det finns inga medlemskap på denna användaren',
    no_properties_title: 'Det finns inga bostäder',
    no_properties_description: 'Det finns inga bostäder på detta projektet',
    no_user_title: 'Inga träffar för',
    no_user_description: 'Kontrollera stavning eller ändra sökord',
    reset_password_request_subject: 'Återställ lösenord',
    remove_membership_success: 'Medlemskapet är nu bortaget från ',
    reset_password_mail_sent: 'Inom kort får användaren ett mail med instruktioner om hur man återställer lösenordet.',
    add_to_membership: 'Lägg till medlemskap',
    yes: 'Ja',
    no: 'Nej',
    organisation: 'Organisation',
    member_added: 'Medlemmen har nu lagts till',
    login: 'Logga in',
    logout: 'Logga ut',
    medium: 'Normal',
    description: 'Beskrivning',
    title: 'Rubrik',
    attachments: 'Bifogade filer',
    attach_pictures: 'Bifoga bilder',
    picture: 'Bild',
    pictures: 'Bilder',
    save_picture: 'Spara bild',
    send: 'Skicka',
    error_oneisrequired: 'Ett val krävs',
    error_required: 'Måste anges',
    recipient_list: 'Mottagarlista',
    location: 'Utrymme',
    search: 'Sök',
    note: 'Anteckning',
    notes: 'Anteckningar',
    product_title: 'Produkt',
    product_color: 'Färg',
    product_model: 'Modell',
    product_function: 'Funktion',
    product_name: 'Namn',
    product_description: 'Beskrivning',
    product_link: 'Produktlänk',
    product_serial_number: 'Serienummer',
    product_extra_information: 'Extra information',
    product_service_advice: 'Serviceråd',
    product_choice: 'Val',
    homepage: 'Startsida',
    product_purchased_at: 'Inköpsställe',
    product_purchased_date: 'Inköpsdatum',
    product_purchased_price: 'Pris',
    product_warranty_end_date: 'Garanti t o m',
    product_manufacturer: 'Tillverkare',
    product_belonging: 'Tillhörighet',
    product_space: 'Utrymme',
    send_mail: 'Skicka mejl',
    send_mail_template: 'Välj mailmall...',
    external_communication: 'Extern kommunikation',
    product: 'Produkt',
    subject: 'Ämne',
    mail_to: 'Till',
    mail_preview: 'Förhandsvisa',
    ticket_ourliving: 'Ärendehantering',
    settings_projects: 'Projektinställningar',
    add_projects: 'Lägg till fastighet',
    settings_mail_templates: 'Mailmallar',
    settings_upload_template: 'Välj mailmall',
    mail_template_type: 'Malltyp',
    settings_mail_tag: 'Tagg',
    settings_projects_add: 'Välj...',
    all_templates: 'Alla mallar',
    upload_mailtemplate: 'Ladda upp mall',
    mail_templates: 'Mailmallar',
    upload: 'Ladda upp',
    user_name: 'Namn',
    removed: 'Borttagen',
    user_id: 'Namn',
    booking_exists: 'Kan ej ta bort. Det finns bokningar på detta slot',
    booking_date_start: 'Från Datum',
    booking_date_end: 'Till Datum',
    name_document: 'Namnge dokument',
    contacts: 'Kontakter',
    contact: 'Kontakt',
    contact_extra_information: 'Extra information',
    all_contacts: 'Alla kontakter',
    new_contact: 'Ny kontakt',
    search_contact: 'Sök kontakt',
    back_to_contacts: 'Tillbaka till alla kontakter',
    contact_name: 'För- & Efternamn',
    contact_mail: 'Mailadress',
    contact_number: 'Nummer',
    contact_company: 'Företag',
    contact_city: 'Stad',
    contact_title: 'Roll',
    contact_list_name: 'Namn',
    delete: 'Ta bort',
    confirm: 'Bekräfta',
    booking: 'Bokning',
    bookings: 'Bokningar',
    booking_date: 'Datum',
    created_at: 'Skapad',
    updated_at: 'Ändrad',
    created_by: 'Skapad av',
    member_search: 'Sök Medlem',
    member_account: 'Email/Konto',
    member: 'Medlem',
    member_property: 'Medlem i bostäder',
    backtomembers: 'Tillbaka till medlemmar',
    member_message: 'Skicka meddelande',
    member_property_name: 'Namn på bostad',
    member_property_number: 'Lgh nr',
    member_property_street: 'Gata',
    membership_instructions: 'Instruktioner medlem',
    project_information_news: 'Nyheter projekt',
    members: 'Medlemmar',
    boardmembers: 'Styrelsemedlemmar',
    boardmember: 'Styrelsemedlem',
    boardmember_email: 'E-postadress',
    boardmember_role: 'Roll',
    role: 'Roll',
    boardmember_subrole: 'Funktion',
    boardmember_extra_info: 'Extra information',
    brf_admin: 'Admin (brf-admin)',
    project_admin: 'Projektadmin',
    boardmember_area: 'Styrelserum',
    vice_chairman: 'Vice Ordförande',
    board_member: 'Styrelsemedlem',
    chairman: 'Ordförande',
    deputy: 'Suppleant',
    secretary: 'Sekreterare',
    cashier: 'Kassör',
    commissioner: 'Ledamot',
    housing_soceity: 'Bostadsrättsförening',
    hours: 'timmar',
    street_name: 'Gatuadress',
    zip_code: 'Postkod',
    commune: 'Kommun',
    project_type: 'BRF',
    membership_subject: 'Medlemskap',
    access_date: 'Tillträdesdag',
    property_count: 'Antal bostäder',
    project_user_name: 'Förvaltare',
    project_owner: 'Projektägare',
    houses_published: 'Bostäder publicerade',
    communication_published: 'Kommunikation publicerad',
    news_new: 'Skapa nyhet',
    calendar_new: 'Skapa ny händelse',
    search_event: 'Sök aktivitet',
    search_news: 'Sök nyhet',
    search_info: 'Sök information',
    search_attendee: 'Sök anmäld',
    news: 'Nyheter',
    back_to_news: 'Tillbaka till alla nyheter',
    back_to_calendar: 'Tillbaka till kalendern',
    back_to_info: 'Tillbaka till information',
    published: 'Publicerad',
    publish_property: ' är nu ',
    property_published: 'publicerad',
    property_unpublished: 'avpublicerad',
    publish_all_checked: 'Publicera markerade bostäder',
    unpublish_all_checked: 'Avpublicera markerade bostäder',
    properties_published: 'Bostäderna är nu publicerade',
    properties_unpublished: 'Bostäderna är nu avpublicerade',
    publish: 'Publicera',
    banner: 'Banner',
    publish_start: 'Publicerad från',
    publish_end: 'Publicerad till',
    notice_sent: 'Notis skickad',
    notify_again: ' Notifiera igen',
    calendar: 'Kalender',
    doocument_group: 'Dokumentgrupp',
    doocument_groups: 'Dokumentgrupper',
    registration_open: 'Anmälan öppen',
    participants: 'Antal medverkande',
    signed: 'Antal anmälda',
    upload_document: 'Ladda upp dokument',
    upload_selected_members: 'Importera valda medlemmar',
    time_slot: 'Bokningstid',
    document: 'Dokument',
    document_group: 'Grupp',
    create_document_group: 'Skapa dokumentgrupp',
    search_document_group: 'Sök dokumentgrupp',
    no_email_suppplied: 'Ingen epost angiven',
    amount: 'Antal',
    new: 'Ny',
    remove: 'Ta bort',
    invite: 'Bjud in',
    invite_again: 'Bjud in igen',
    invite_all: 'Bjud in alla',
    create_new: 'Skapa nytt',
    import_members: 'Välj fil att importera',
    edit: 'Ändra',
    posts: 'Publiceringar',
    event_date: 'Datum för händelse',
    location_calendar: 'Plats',
    time: 'Tidpunkt',
    public_documents: 'Publika dokument',
    copy: 'Kopiera',
    copied: 'Kopierad',
    about: 'Om',
    general: 'Allmänt',
    forgot_password: 'Glömt lösenord',
    reset_password: 'Återställ lösenord',
    residences: 'Bostäder',
    residence: 'Residence',
    community: 'Community',
    continue: 'Fortsätt',
    new_password: 'Nytt lösenord',
    confirm_password: 'Bekräfta lösenord',
    reset_no_valid_token: 'Ogiltig länk ',
    reset_error: 'Något gick fel. Försök igen',
    member_exist_error: 'Medlemmen finns redan',
    save_error: 'Kunde inte spara. Försök igen',
    reset_no_exists: 'Hittar ej länk. Vänlig försök igen',
    reset_expired: 'Länken har gått ut',
    reset_password_not_matched: 'Lösenord matchar ej',
    reset_password_changed: 'Lösenordet är bytt. Vänligen logga in på nytt.',
    reset_mail_no_exists: 'Vänligen ange en giltig e-postadress',
    reset_mail_send: 'E-post är skickat till den angivna adressen',
    confirm_account: 'Bekräfta Email',
    account: 'Konto',
    mail_error: 'Ett fel har inträffats. Var vänlig försök igen',
    mail_mail_send: 'Bekräftelsemail har skickats',
    mail_expired: 'Länken har gått ut',
    booking_resource: 'Bokningsresurs',
    show: 'Visa',
    endtime: 'Sluttid',
    starttime: 'Starttid',
    user: 'Användare',
    order_public_site: 'Beställ hemsida',
    theme: 'Välj utseende',
    text: 'Text',
    background_image: 'Bakgrundsbild',
    preview: 'Förhandsgranska',
    previewing: 'Förhandsgranskning',
    delete_page: 'Ta bort sida',
    select_theme: 'Välj tema',
    write_something: 'Skriv något här',
    header: 'Rubrik',
    first_name: 'Förnamn',
    last_name: 'Efternamn',
    telephone_number: 'Telefonnummer',
    address_1: 'address 1',
    address_2: 'address 2',
    light: 'Ljus',
    dark: 'Mörk',
    add_item: 'Lägg till',
    themes: 'Teman',
    create_theme: 'Skapa tema',
    activate_homepage: 'Aktivera hemsida',
    activate_homepage_p1:
        '  För att komma igång med hemsida för eran fastighet måste ni beställa aktivering, genom att trycka på knappen nedan.',
    activate_homepage_p2:
        'Efter det tar det ca 2-3 dagar innan eran hemsida är igång. Dröjer det eller att ni har andra funderingar kontakta support@ourliving.se.',
    background_color_menu: 'Bakgrundsfärgmeny',
    background_color_page: 'Sidans bakgrundsfärg',
    background_color_footer: 'Bakgrundsfärg sidfot',
    color_footer_text: 'Sidfot textfärg',
    font_title: 'Titel font',
    font_normal_text: 'Font brödtext',
    content: 'Innehåll',
    table: 'Tabell',
    category: 'Kategorier',
    unit: 'Enheter',
    type: 'Typ',
    maintenance: 'Underhåll',
    service_partner: 'Leverantör',
    service_partner_name: 'Leverantörs namn',
    createNewSevicePartner: 'Skapa ny leverantör',
    booking_slot_type_day: 'dygn',
    booking_slot_type_slot: 'slot',
    booking_slot_type: 'Kalendertyp',
    fill_in_description: 'Du måste fylla i beskrivningen',
    analys: 'Analys',
    complete_maintenance: 'Slutför underhåll',
    maintenance_status_completed: 'Slutförd',
    contact_person: 'Kontaktperson',
    public_site_header: 'Hemsida',
    help_header: 'Hjälp',
    blog_header: 'Blog',
    resend_notification_success: 'Notis Skickad',
    resend_notification_error: 'Något gick fel',
    resend_notification_loading: 'Skickar notis',
    resend_notification_idle: 'Notifiera igen',
    error_file_type: 'Måste vara en fil',
    error_must_be_type: 'Måste vara av typ',
    back_to_information: 'Tillbaka till information',
    information_new: 'Skapa information',
    wont_be_uploaded: 'kommer inte laddas upp',
    name_missing: 'Namn saknas',
    email_missing: 'Email saknas',
    email_Send: 'Skickad',
    email_Delivery: 'Levererad',
    email_Open: 'Öppnad',
    email_Complaint: 'Mailet blev markerat som spam',
    email_Bounce: 'Kunde inte leverera mail',
    email_Link: 'Öppnat länk',
    invite_email_status: 'Status på mailinbjudan',
    create_new_job: 'Skapa nytt jobb',
    tabel: 'Tabell',
    add_member: 'Lägg till medlem',
    invite_member: 'Bjud in medlem',
    select_member: 'Välj medlem',
    addons: 'Tillval',
    addon: 'Inredingsval',
    binder: 'Bopärm',
    alert: 'Larm',
    key: 'Dörrar',
    ticket: 'Ärenden',
    signing: 'Signering',
    community_preview: 'Funktioner för fastighet (community)',
    addon_invite_members: 'Tillval Inbjudan',
    no_help_items: 'Det finns inga hjälpsidor än',
    no_blog_items: 'Det finns inga bloggsidor än',
    ourliving_news: 'Ourliving Nyheter',
    contract: 'Avtal',
    documents: 'Dokument',
    help: 'Hjälp',
    content_news: 'Nyheter',
    content_information: 'Informationssidor',
    content_calendar: 'Kalender',
    board_invite_member: 'Styrelsemedlem inbjudan',
    digital_key: 'Lås',
    board_content_calendar: 'Styrelse kalender',
    board_content_information: 'Styrelse informationssidor',
    member_content_news: 'Medlem Nyheter',
    board_content_news: 'Styrelse Nyheter',
    member_content_calendar: 'Medlem Kalender',
    member_content_information: 'Medlem informationssidor',
    public_site: 'Hemsida',
    mail: 'Mejl',
    community_mail: 'Community mejl',
    mail_name: 'Mejladress',
    save_success: 'Lyckades spara informationen',
    failed_to_fetch: 'Kunde inte hämta data',
    no_project: 'Inget projekt angivet.',
    mail_already_exists: 'Denna mailadressen finns redan',
    published_at: 'Publicerad',
    street: 'Adress',
    apartment_number: 'Lgh nummer',
    change_email: 'Ändra mailadress',
    updated_email_success: 'Mailadressen har nu uppdaterats',
    updated_email_error: 'Mailadressen gick inte att uppdatera',
    current_email: 'Nuvarande mailadress',
    new_email: 'Ny mailadress',
    email_already_exist: 'Mailadressen finns redan',
};
