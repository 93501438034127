import { PgBossJob } from '@app/api/models/PgBoss';
import React, { useState } from 'react';
import { HTMLAttributes } from 'react';
import styled from 'styled-components';
import { Dropdown } from '../../../components/Dropdown/Dropdown';
import Spinner from '../../../components/Spinner/Spinner';
import { JobColumns } from '../components/Columns';
import Table from '../components/JobTable';
import { trpc } from '../../../lib/trpc';

const Container = styled.div({
    position: 'relative',
});
const StyledSpinner = styled(Spinner)({
    right: 0,
    top: 0,
    position: 'absolute',
});
const JobsTablePage = ({ ...rest }: HTMLAttributes<HTMLDivElement>) => {
    const [filter, setFilter] = useState<{
        state: PgBossJob['state'];
    }>({
        state: 'failed',
    });
    const { data: IPageJobs, isFetching } = trpc.PgBossApi.getTasks.useQuery(
        {
            page: 1,
            pageSize: 20,
            filter,
        },
        {
            refetchInterval: 60000,
            keepPreviousData: true,
        }
    );

    const states: PgBossJob['state'][] = ['active', 'completed', 'created', 'failed'];
    return (
        <Container {...rest}>
            {isFetching && <StyledSpinner />}
            <div
                style={{
                    display: 'flex',
                    gap: '2rem',
                    alignItems: 'center',
                    paddingBottom: '2rem',
                }}
            >
                <div>Filter:</div>
                <Dropdown
                    value={filter.state}
                    onChange={(value) => setFilter((prev) => ({ ...prev, state: value }))}
                    options={states.map((state) => ({
                        label: state,
                        value: state,
                    }))}
                />
            </div>
            <Table columns={JobColumns} data={IPageJobs?.items} />
        </Container>
    );
};

export default JobsTablePage;
