import styled from '@emotion/styled';
import dayjs from 'dayjs';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { colors } from '../../../components/Shared/Style/colors';
import { L } from '../../../lib/i18n';
import VerticalMoreIcon from '../../../components/Icon/vertical-dots.svg';
import { BlogItem } from '@app/api/public/BlogApi';

const StyledNavItem = styled.li({});
const StyledNavLink = styled(NavLink)({
    textDecoration: 'none',
    color: 'black',
    padding: '10px 20px',
    borderLeft: '6px solid',
    borderColor: 'gray',
    width: '100%',
    marginBottom: '2rem',
    backgroundColor: 'white',
    cursor: 'pointer',
    borderRadius: '5px',
    listStyle: 'none',
    display: 'block',
    '&.published': {
        borderColor: '#29CC97',
    },
    '&.active': {
        boxShadow: `0 0 3px ${colors.primary}`,
    },
});

const SmallInfo = styled.div({
    fontSize: '0.8rem',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '1rem',
});

const IconWrapper = styled.div({
    width: '1rem',
});

const FlexWrapper = styled.div({
    display: 'flex',
    alignItems: 'center',
});

const Wrapper = styled.div({
    marginLeft: '1rem',
});

const PageInfo = styled.b({
    textTransform: 'capitalize',
});

type Props = {
    blogItem: BlogItem;
    innerRef: (element?: HTMLElement | null | undefined) => any;
} & React.DetailedHTMLProps<React.LiHTMLAttributes<HTMLLIElement>, HTMLLIElement>;
const NavItem = ({ blogItem, innerRef, ...rest }: Props) => {
    return (
        <StyledNavItem
            onClick={() => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })}
            ref={innerRef}
            {...rest}
        >
            <StyledNavLink to={`/blog/${blogItem.id}`} className={blogItem.published_at ? 'published' : ''}>
                <FlexWrapper>
                    <IconWrapper>
                        <VerticalMoreIcon />
                    </IconWrapper>
                    <Wrapper>
                        <h1>{blogItem.title}</h1>
                        <SmallInfo>
                            <span>
                                <PageInfo>{blogItem.application}</PageInfo>
                            </span>
                            <span>
                                {L('last_edited')} <b>{dayjs(blogItem.updated_at).format('YYYY-MM-D')}</b>
                            </span>
                        </SmallInfo>
                    </Wrapper>
                </FlexWrapper>
            </StyledNavLink>
        </StyledNavItem>
    );
};

export default NavItem;
