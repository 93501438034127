import * as React from 'react';
import styled from '@emotion/styled';

import { style } from '../Shared/Style';
import { NavLink } from 'react-router-dom';
import SliderMenu from '../SliderMenu/SliderMenuContent';
import { FC } from 'react';

interface BaseCssProps {
    children?: any;
    theme?: any;
}

interface MyProps extends BaseCssProps {
    background?: string;
    title?: string;
}

const Container = styled.div<MyProps>((props) => ({
    paddingBottom: 0,
    backgroundColor: props.theme.background,
    display: 'grid',
    gridTemplateColumns: 'max-content 1fr max-content',
    gridTemplateAreas: `
        "title children slider "
    `,

    gridTemplateRows: 'min-content auto',
    marginBottom: '1rem',
}));

const Title = styled.div<MyProps>((props) => ({
    fontSize: style.fontSize.gamma,
    fontWeight: style.fontWeight.bold,
    textTransform: 'capitalize',
}));

const BackTo = styled(NavLink)<MyProps>((props) => ({
    textDecoration: 'none',
    fontWeight: style.fontWeight.thin,
    fontSize: style.fontSize.seta,
    lineHeight: '20px',
    display: 'flex',
    alignItems: 'center',
    letterSpacing: '0.2px',
    textAlign: 'left',
    color: style.colors.black,
    cursor: 'pointer',
}));

interface Props {
    title: string;
    // user: User;
    children?: any;
    back?: any;
    to?: string;
    text?: string;
    sliderMenu?: boolean;
    Links?: JSX.Element;
    // theme?: any;
    // signOut: () => void;
}

export const Header: FC<Props> = (props) => {
    return (
        <Container>
            <Title style={{ gridArea: 'title', alignSelf: 'end' }}>{props.title}</Title>
            <div style={{ gridArea: 'children' }}>{props.children}</div>
            {props.sliderMenu && <SliderMenu Links={props.Links} />}
        </Container>
    );
};
