import * as React from 'react';
import { L } from '../../lib/i18n';
import PageTitle from '../../components/typography/PageTitle';
import { Route, Switch } from 'react-router-dom';
import MailTemplatePage from './MailTemplates/MailTemplatePage';
import ModalProvider from '../../components/Modal/Context/ModalContext';

export const MailTemplatesPage = () => {
    return (
        <>
            <PageTitle>{L('mail_templates')}</PageTitle>
            <Switch>
                <Route path={'/mail-templates'}>
                    <ModalProvider>
                        <MailTemplatePage />
                    </ModalProvider>
                </Route>
            </Switch>
        </>
    );
};
