import React, { useEffect } from 'react';
import { Dropdown } from '../../../components/Dropdown/Dropdown';
import { trpc } from '../../../lib/trpc';

type Props = {
    accountId: number;
    setProjectId: React.Dispatch<React.SetStateAction<number | undefined>>;
    projectId: number | undefined;
};
const ProjectDropdown = ({ accountId, setProjectId, projectId }: Props) => {
    const { data: projects } = trpc.ProjectFeturesApi.listProjects.useQuery(
        { accountId },
        {
            enabled: Boolean(accountId),
        }
    );

    useEffect(() => {
        setProjectId(undefined);
    }, [accountId]);

    const projectOptions = projects?.map((d) => ({ label: d.name, value: d.id }));

    return <Dropdown value={projectId} onChange={(value) => setProjectId(value)} options={projectOptions || []} />;
};

export default ProjectDropdown;
