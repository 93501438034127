import { useHistory } from 'react-router-dom';
import { trpc } from '../../../lib/trpc';

const useSaveBlogListItem = () => {
    const utils = trpc.useContext();

    const history = useHistory();

    return trpc.BlogApi.save.useMutation({
        onSuccess: (data) => {
            const blogItems = utils.BlogApi.listByApplication.getData({ application: data.application });
            if (blogItems) {
                utils.BlogApi.listByApplication.setData({ application: data.application }, [...blogItems, data]);
            }
            history.push(`/blog/${data.id}`);
        },
        onSettled: (_data, _error, variables) => {
            utils.BlogApi.listByApplication.invalidate({ application: variables.item.application });
        },
    });
};

export default useSaveBlogListItem;
