import toast from 'react-hot-toast';
import { L } from '../../../lib/i18n';
import z from 'zod';
import { ListingProperty } from '@app/api/models/Properties';
import { client } from '../../../lib/trpc';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { organisationQueryKeys } from '../../members/hooks/useGetOrganisations';

const errorSchema = z.object({ name: z.string(), status: z.string() }).array();

const publishChecked = async ({
    propertiesArray,
    publish,
}: {
    propertiesArray: ListingProperty[];
    publish: boolean;
}) => {
    const response = await Promise.all(
        propertiesArray.map(async (property) => {
            try {
                await client.PropertiesApi.setPublishedAt.mutate({
                    publish,
                    propertyId: property.id,
                });
                return { name: property.name, status: 'success' };
            } catch (error) {
                return { name: property.name, status: 'unknown' };
            }
        })
    );
    //throw new Error(`Inbjudan kunde inte skickas till ${response.email}`);
    const errors = response.find((item) => item.status === 'error' || item.status === 'unknown');
    if (errors) throw response;
};
const usePublishCheckedProperties = () => {
    const queryClient = useQueryClient();
    return useMutation(publishChecked, {
        onSuccess: (data, variables) => {
            variables.publish ? toast.success(L('properties_published')) : toast.success(L('properties_unpublished'));
        },
        onError: (err) => {
            console.log(err);

            const validatedError = errorSchema.safeParse(err);
            /* Shows error message  */
            if (validatedError.success) {
                const errors = validatedError.data.filter(
                    (item) => item.status === 'error' || item.status === 'unknown'
                );
                errors.map((item) => {
                    toast.error(`Det gick inte att publicera/avpublicera ${item.name}`);
                });
                const success = validatedError.data.filter((item) => item.status === 'success');
                success.length && toast.success(`${success.length} bostäder publicerade`);
            }
        },
        onSettled: () => {
            queryClient.invalidateQueries(organisationQueryKeys.propertyLists());
        },
    });
};

export default usePublishCheckedProperties;
