import React, { FormEvent, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import styled from '@emotion/styled';
import Quill from '../../../components/Quill/Quill';
import { L } from '../../../lib/i18n';
import Label from '../../../components/Forms/Label';
import Input from '../../../components/Forms/Input';
import { Dropdown } from '../../../components/Dropdown/Dropdown';
import { Button } from '../../../components/Buttons/Button';
import {
    applicationsOptions,
    communityOptions,
    propertyManagementOptions,
    residenceOptions,
} from '../lib/DropdownOptions';
import { HelpItem } from '@app/api/models/Help';
import { trpc } from '../../../lib/trpc';
import useSaveHelpItem from '../hooks/useSaveHelpItem';

const Container = styled.div({
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '4px',
    marginBottom: '5rem',
});

const AbortWrapper = styled(Link)({
    textDecoration: 'none',
});

const ButtonWrapper = styled.div({
    display: 'flex',
    gap: '1rem',
    margin: '1rem 0px',
});

const AddPageView = () => {
    const [pageName, setPageName] = useState('');
    const [projectName, setProjectName] = useState<HelpItem['application']>('community');
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const { mutate: savePageListItem, status } = useSaveHelpItem();
    const { data: residenceItems } = trpc.HelpApi.listByApplication.useQuery('residence');
    const { data: communityItems } = trpc.HelpApi.listByApplication.useQuery('community');
    const { data: propertyManagementItems } = trpc.HelpApi.listByApplication.useQuery('property_management');
    const [error, setError] = useState({
        title: '',
        description: '',
        pageName: '',
    });
    const history = useHistory();

    const avalibleCommunityOptions = [...communityOptions].filter(
        (option) => !communityItems?.find((item) => item.page_name === option.value)
    );

    const avalibleResidenceOptions = [...residenceOptions].filter(
        (option) => !residenceItems?.find((item) => item.page_name === option.value)
    );

    const avaliblePropertyManagementOptions = [...propertyManagementOptions].filter(
        (option) => !propertyManagementItems?.find((item) => item.page_name === option.value)
    );

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!title) {
            setError((prev) => ({ ...prev, title: 'Du måste fylla i en titel' }));
            return;
        }
        if (!description) {
            setError((prev) => ({ ...prev, description: 'Du måste fylla i en beskrivning.' }));
            return;
        }
        if (!pageName) {
            setError((prev) => ({ ...prev, pageName: 'Du måste välja en sida.' }));
            return;
        }
        if (title && description && pageName)
            savePageListItem(
                {
                    title,
                    description,
                    page_name: pageName,
                    application: projectName,
                },
                {
                    onSuccess: (data) => {
                        history.push(`/help/${data.id}`);
                    },
                }
            );
    };

    return (
        <Container>
            <form onSubmit={handleSubmit}>
                <Label error={error?.title} title={L('title')}>
                    <Input placeholder="Rubrik..." onChange={(e) => setTitle(e.target.value)} />
                </Label>
                <Label title={L('Applikation')} />
                <Dropdown
                    value={projectName}
                    onChange={(value) => setProjectName(value)}
                    options={applicationsOptions}
                />
                {projectName === 'community' && (
                    <>
                        <Label title={L('Sidonamn')} error={error?.pageName} />
                        <Dropdown
                            value={pageName}
                            onChange={(value) => setPageName(value)}
                            options={avalibleCommunityOptions}
                        />
                    </>
                )}
                {projectName === 'residence' && (
                    <>
                        <Label title={L('Sidonamn')} error={error?.pageName} />
                        <Dropdown
                            value={pageName}
                            onChange={(value) => setPageName(value)}
                            options={avalibleResidenceOptions}
                        />
                    </>
                )}
                {projectName === 'property_management' && (
                    <>
                        <Label title={L('Sidonamn')} error={error?.pageName} />
                        <Dropdown
                            value={pageName}
                            onChange={(value) => setPageName(value)}
                            options={avaliblePropertyManagementOptions}
                        />
                    </>
                )}
                <Label title={L('content')} error={error?.description} />
                <Quill onChange={(value) => setDescription(value)} />

                <ButtonWrapper>
                    {status !== 'loading' && (
                        <AbortWrapper to="/help">
                            <Button variant={'filledPrimary'}>{L('cancel')}</Button>
                        </AbortWrapper>
                    )}
                    <Button variant={'filledPrimary'} type="submit">
                        {status === 'loading' ? L('save') : L('add_item')}
                    </Button>
                </ButtonWrapper>
            </form>
        </Container>
    );
};

export default AddPageView;
