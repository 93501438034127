import * as React from 'react';
import { L } from '../../lib/i18n';
import PageTitle from '../../components/typography/PageTitle';
import { Route, Switch } from 'react-router-dom';
import ModalProvider from '../../components/Modal/Context/ModalContext';
import PropertiesListPage from './ListPage/PropertiesListPage';
import PropertyDetailPage from './DetailPage/PropertyDetailPage';
export const PropertyPage = () => {
    return (
        <>
            <PageTitle>{L('properties')}</PageTitle>
            <Switch>
                <Route exact path={'/properties'}>
                    <ModalProvider>
                        <PropertiesListPage />
                    </ModalProvider>
                </Route>
                <Route path={'/properties/:id'}>
                    <ModalProvider>
                        <PropertyDetailPage />
                    </ModalProvider>
                </Route>
            </Switch>
        </>
    );
};
