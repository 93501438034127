import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { L } from '../../../lib/i18n';
import { client, trpc } from '../../../lib/trpc';
import { organisationQueryKeys } from '../../members/hooks/useGetOrganisations';
import { projectQueryKeys } from '../../members/hooks/useGetProject';

export const propertyQueryKeys = {
    all: [{ scope: 'project' }] as const,
    lists: () => [{ ...propertyQueryKeys.all[0], entity: 'list' }] as const,
    list: (id: number) => [{ ...propertyQueryKeys.lists()[0], id }] as const,
};

const publishProperty = async (variables: { propertyId: number; publish: boolean }) => {
    return client.PropertiesApi.setPublishedAt.mutate(variables);
};

const usePublishProperty = () => {
    const queryClient = useQueryClient();
    return useMutation(
        (variables: { propertyId: number; publish: boolean; propertyName: string; projectId: number }) =>
            publishProperty(variables),
        {
            onSuccess: (data, variables, context) => {
                toast.success(
                    `${variables.propertyName} ${L('publish_property')} ${
                        variables.publish ? L('property_published') : L('property_unpublished')
                    }`
                );
            },
            onError: (err) => {
                /* Shows error message */
                const error = typeof err === 'string' ? err : new Error(L('reset_error')).message;
                const message = error;
                toast.error(`${L(message)}`);
            },
            onSettled: (data, err, variables) => {
                queryClient.invalidateQueries(organisationQueryKeys.propertyList(variables.projectId));
            },
        }
    );
};

export { usePublishProperty };
