import React from 'react';
import css from './Icon.css';

interface IIconProps {
    className?: string;
    state?: 'success' | 'failed' | 'spin';
    size?: 'small' | 'large' | 'mSmall' | '100';
    light?: boolean;
    onClick?: (e: any) => void;
    fill?: string;
    stroke?: string;
    style?: any;
}

export class SvgIcon extends React.Component {
    props: IIconProps & { iconId: string };

    onClick = (e) => {
        e.preventDefault();
        if (this.props.onClick) {
            this.props.onClick(e);
        }
    };

    render() {
        const { fill, iconId, light, state, size, className, ...rest } = this.props;
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        const SvgCodeIn = require('!!raw-loader!./' + iconId + '.svg');
        const SvgCode = (SvgCodeIn && SvgCodeIn.default) || SvgCodeIn;
        const classNames = [css.svg];
        if (fill) {
            classNames.push(css.fill);
        }
        if (fill) {
            classNames.push(css.fill);
        }
        if (state) {
            classNames.push(css['state-' + state]);
        }
        if (light) {
            classNames.push(css.light);
        }
        if (className) {
            classNames.push(className);
        }
        if (size) {
            classNames.push(css['size-' + size]);
        }

        if ('onClick' in this.props) {
            classNames.push(css.clickable);
        }
        const resultProps = {
            ...rest,
            className: classNames.join(' '),
            dangerouslySetInnerHTML: { __html: SvgCode },
        };

        if ('onClick' in this.props) {
            return <button tabIndex={0} {...resultProps} onClick={this.onClick} />;
        } else {
            return <span {...resultProps} />;
        }
    }
}

export const Icon = {
    Binder(props: IIconProps) {
        return <SvgIcon {...props} iconId="books" />;
    },
    Dashboard(props: IIconProps) {
        return <SvgIcon {...props} iconId="house-pricing" />;
    },
    Settings(props: IIconProps) {
        return <SvgIcon {...props} iconId="privacy-settings" />;
    },
    Tickets(props: IIconProps) {
        return <SvgIcon {...props} iconId="property-agreement" />;
    },
    MoreVertical(props: IIconProps) {
        return <SvgIcon {...props} iconId="ic_more_vert_18px" />;
    },
    Attachment(props: IIconProps) {
        return <SvgIcon {...props} iconId="attachment" />;
    },
    SortUp(props: IIconProps) {
        return <SvgIcon {...props} iconId="small-triangle-up" />;
    },
    SortDown(props: IIconProps) {
        return <SvgIcon {...props} iconId="small-triangle-down" />;
    },
    SignOut(props: IIconProps) {
        return <SvgIcon {...props} iconId="signout" />;
    },
    Messages(props: IIconProps) {
        return <SvgIcon {...props} iconId="email" />;
    },
    ContentCopy(props: IIconProps) {
        return <SvgIcon {...props} iconId="content-copy" />;
    },
    Image(props: IIconProps) {
        return <SvgIcon {...props} iconId="image" />;
    },
    Document(props: IIconProps) {
        return <SvgIcon {...props} iconId="single-folded-content" />;
    },
    TrashCan(props: IIconProps) {
        return <SvgIcon {...props} iconId="trash-simple" />;
    },
    Link(props: IIconProps) {
        return <SvgIcon {...props} iconId="link-72" />;
    },
    Upload(props: IIconProps) {
        return <SvgIcon {...props} iconId="square-upload" />;
    },
    Member(props: IIconProps) {
        return <SvgIcon {...props} iconId="single-02" />;
    },
    BoardMember(props: IIconProps) {
        return <SvgIcon {...props} iconId="boardmember" />;
    },
    Contact(props: IIconProps) {
        return <SvgIcon {...props} iconId="user-frame-32" />;
    },
    News(props: IIconProps) {
        return <SvgIcon {...props} iconId="paper" />;
    },
    Calendar(props: IIconProps) {
        return <SvgIcon {...props} iconId="calendar-date-2" />;
    },
    Information(props: IIconProps) {
        return <SvgIcon {...props} iconId="info" />;
    },
    Property(props: IIconProps) {
        return <SvgIcon {...props} iconId="fav-property" />;
    },
    Profile(props: IIconProps) {
        return <SvgIcon {...props} iconId="circle-08" />;
    },
    Add(props: IIconProps) {
        return <SvgIcon {...props} iconId="c-add" />;
    },
    Maintenance(props: IIconProps) {
        return <SvgIcon {...props} iconId="settings" />;
    },
    Chart(props: IIconProps) {
        return <SvgIcon {...props} iconId="chart-bar" />;
    },
    List(props: IIconProps) {
        return <SvgIcon {...props} iconId="bullet-list" />;
    },
    Lists(props: IIconProps) {
        return <SvgIcon {...props} iconId="list" />;
    },
    Work(props: IIconProps) {
        return <SvgIcon {...props} iconId="work" />;
    },
    Project(props: IIconProps) {
        return <SvgIcon {...props} iconId="apartment" />;
    },
    Sign(props: IIconProps) {
        return <SvgIcon {...props} iconId="sign" />;
    },
    Mail(props: IIconProps) {
        return <SvgIcon {...props} iconId="mail" />;
    },
    Addon(props: IIconProps) {
        return <SvgIcon {...props} iconId="app-store" />;
    },
    Help(props: IIconProps) {
        return <SvgIcon {...props} iconId="s-question" />;
    },
    Blog(props: IIconProps) {
        return <SvgIcon {...props} iconId="blog" />;
    },
};
