import React from 'react';
import { useQueryErrorResetBoundary } from '@tanstack/react-query';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorNotice from './ErrorNotice';
import { useHistory } from 'react-router';

type Props = { children?: React.ReactNode };

const ErrorNoticehandler: React.FC<Props> = ({ children }) => {
    const { reset } = useQueryErrorResetBoundary();
    const history = useHistory();
    return (
        <ErrorBoundary
            onReset={() => {
                reset();
                history.push('/');
            }}
            fallbackRender={({ resetErrorBoundary, error }) => (
                <>
                    <ErrorNotice text={'något gick fel ' + error.message} onClick={() => resetErrorBoundary()} />
                </>
            )}
        >
            {children}
        </ErrorBoundary>
    );
};

export default ErrorNoticehandler;
