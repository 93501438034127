import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import styled from '@emotion/styled';
import { Button } from '../../../../components/Buttons/Button';
import Input from '../../../../components/Forms/Input';
import Label from '../../../../components/Forms/Label';
import { Dropdown } from '../../../../components/Dropdown/Dropdown';
import { L } from '../../../../lib/i18n';
import { trpc } from '../../../../lib/trpc';
import toast from 'react-hot-toast';

const StyledH1 = styled.div({
    fontSize: '1rem',
});

const AddMember = () => {
    const { mutate, isLoading } = trpc.PgBossApi.runJob.useMutation();
    const { data: allProjects } = trpc.ProjectsApi.getAllProjects.useQuery();
    const utils = trpc.useContext();
    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            projectId: '',
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().min(1, 'Too Short!').required(),
            email: Yup.string().email('Invalid Email').required(),
            projectId: Yup.number().moreThan(0).required(),
        }),
        onSubmit: (values) => {
            mutate(
                {
                    name: 'invite-member-project',
                    data: {
                        member: {
                            name: values.name,
                            email: values.email,
                        },
                        projectId: +values.projectId,
                    },
                },
                {
                    onError: () => {
                        toast.error(`Kunde inte skapa jobb för att lägga till medlem: ${values.name}`);
                    },
                    onSuccess: () => {
                        toast.success(
                            `Jobbet för att lägga till medlem: ${values.name} är skapat, du hittar jobbet i tabell vyn`,
                            {
                                duration: 8000,
                            }
                        );
                    },
                    onSettled: () => {
                        utils.PgBossApi.getTasks.invalidate();
                    },
                }
            );
        },
    });
    return (
        <div>
            <StyledH1>{L('add_member')}</StyledH1>
            <form onSubmit={formik.handleSubmit}>
                <Label
                    title={L('name')}
                    style={{ marginBottom: '2rem' }}
                    error={formik.touched.name ? formik.errors.name : undefined}
                >
                    <Input
                        name={'name'}
                        id={'name'}
                        value={formik.values.name}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                    />
                </Label>
                <Label
                    title="Email"
                    style={{ marginBottom: '2rem' }}
                    error={formik.touched.email ? formik.errors.email : undefined}
                >
                    <Input
                        name={'email'}
                        id={'email'}
                        value={formik.values.email}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                    />
                </Label>

                <Label
                    title={L('project')}
                    style={{ marginBottom: '2rem' }}
                    error={formik.touched.projectId ? formik.errors.projectId : undefined}
                >
                    <Dropdown
                        value={formik.values.projectId}
                        onChange={(value) => {
                            formik.setFieldValue('projectId', value);
                        }}
                        placeholder={L('select_project')}
                        options={
                            allProjects?.map((project) => ({
                                label: `${project.name} | Id: ${project.id}`,
                                value: project.id,
                            })) || []
                        }
                        onBlur={formik.handleBlur}
                    />
                </Label>
                <Button type="submit" disabled={isLoading} variant="primary">
                    {L('run_job')}
                </Button>
            </form>
        </div>
    );
};

export default AddMember;
