import styled from '@emotion/styled';
import React, { FC } from 'react';
import { style } from '../../Shared/Style';

const AreaCardWraper = styled.nav({
    display: 'grid',
    gap: '1.2rem',
    gridTemplateColumns: 'repeat( auto-fill, minmax(200px, 1fr) )',
    gridTemplateRows: 'auto',
    marginTop: '12px',
    marginBottom: style.margin.l,
});

const AreaCards: FC = ({ children }) => {
    return <AreaCardWraper>{children}</AreaCardWraper>;
};

export default AreaCards;
