import React, { useMemo, useState } from 'react';
import CardWithDivider from '../../../components/Layout/CardWithDivider';
import { L } from '../../../lib/i18n';
import { useParams } from 'react-router';
import EmptyState from '../../../components/EmptyState/EmptyState';
import { useGetOrganisationPropertiesByProject } from '../../members/hooks/useGetOrganisations';
import { COLUMNSPROPERTIES } from './ColumnsProperties';
import { escapeRegex } from '../../../lib/regex';
import useTableCheckbox from './TableProvider';
import TopArea from './TopArea';
import TableRowSelection from './TableRowSelection';
import { useGetProjectByProjectId } from '../hooks/useGetProject';

const PropertyDetailPage = () => {
    const { id } = useParams<{ id: string }>();

    const { data: propertyList, status: propertyListStatus } = useGetOrganisationPropertiesByProject(+id);
    const { data: projectData } = useGetProjectByProjectId(+id);

    const [filter, setFilter] = useState('');

    const searchRegexp = new RegExp(`(${escapeRegex(filter)})`, 'gmi');

    const filteredData = useMemo(() => {
        return (
            propertyList?.filter((item) => {
                if (!filter) return true;
                return item.name.match(searchRegexp);
            }) || []
        );
    }, [filter, propertyList]);
    const useTable = useTableCheckbox({ data: filteredData, columns: COLUMNSPROPERTIES });

    const selectedData = useTable.selectedFlatRows.map((data) => {
        return data.original;
    });

    if (propertyListStatus === 'success') {
        return (
            <>
                <CardWithDivider
                    topArea={
                        <TopArea
                            projectName={projectData?.name || ''}
                            selectedProperties={selectedData}
                            onSubmit={(value: boolean) => useTable.toggleAllRowsSelected(value)}
                        />
                    }
                    mainArea={
                        <>
                            {filteredData.length > 0 ? (
                                <TableRowSelection useTable={useTable} />
                            ) : (
                                <EmptyState>
                                    <EmptyState.Title>{L('no_properties_title')}</EmptyState.Title>
                                    <EmptyState.Description>{L('no_properties_description')}</EmptyState.Description>
                                </EmptyState>
                            )}
                        </>
                    }
                />
            </>
        );
    }
    return <div>No project</div>;
};

export default PropertyDetailPage;
