import React from 'react';
import AreaCard from '../../../components/AreaCard/AreaCard';
import AreaCards from '../../../components/AreaCard/AreaCards/AreaCards';
import CardWithDivider from '../../../components/Layout/CardWithDivider';
import StyledSwitch from '../../../components/StyledSwitch/StyledSwitch';
import CardTitle from '../../../components/typography/CardTitle';
import { L } from '../../../lib/i18n';
import { trpc } from '../../../lib/trpc';
import { GetIconName } from '../functions/getIconName';

export const PreviewAreas = [
    // 'members',
    // 'project',
    // 'tickets',
    // 'boardmembers',
    // 'contacts',
    // 'board_content_news',
    // 'board_content_calendar',
    // 'board_content_information',
    // 'member_content_news',
    // 'member_content_calendar',
    // 'member_content_information',
    'maintenance',
    // 'help',
    // 'ourliving_news',
    // 'contract',
    // 'documents',
    // 'service_partner',
    'mail',
    'public_site',
    'alert',
    'document',
    // 'properties',
    // 'booking',
];

const cleanupOldFeatures = (features: string[]) => {
    return features.filter((feature) => PreviewAreas.includes(feature));
};
type Props = {
    projectId: number;
};
const CommunityPreview = ({ projectId }: Props) => {
    const utils = trpc.useContext();
    const { data: enabledPreviewFeatures } = trpc.ProjectSettingsApi.getCommunityFeaturePreview.useQuery({ projectId });

    const { mutate: setCommunityFeature } = trpc.ProjectSettingsApi.setProjectPreviewFeatures.useMutation({
        onSuccess: () => {
            utils.ProjectSettingsApi.getCommunityFeaturePreview.invalidate({ projectId });
        },
    });

    const handleSwitch = (area: string) => {
        if (!enabledPreviewFeatures) return;
        const newEnabledPreviewFeatures = enabledPreviewFeatures?.features.includes(area)
            ? enabledPreviewFeatures?.features.filter((feature) => feature !== area)
            : [...enabledPreviewFeatures?.features, area];
        setCommunityFeature({ projectId, features: cleanupOldFeatures(newEnabledPreviewFeatures) });
    };
    return (
        //
        //
        <CardWithDivider
            topArea={<CardTitle>{L('community_preview')}</CardTitle>}
            mainArea={
                <AreaCards>
                    {PreviewAreas.map((area) => {
                        return (
                            <AreaCard
                                Icon={GetIconName(area)}
                                name={L(area)}
                                description={L(area)}
                                key={area}
                                Switch={
                                    <StyledSwitch
                                        checked={enabledPreviewFeatures?.features.includes(area)}
                                        onClick={() => handleSwitch(area)}
                                    />
                                }
                            />
                        );
                    })}
                </AreaCards>
            }
        />
    );
};

export default CommunityPreview;
