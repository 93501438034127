import React from 'react';
import styled from '@emotion/styled';
import { MainMenuItem } from './MainMenuItem';
import { L } from '../../lib/i18n';
import { navigation } from '../../PrivateRoutes';

const Container = styled.div({
    minWidth: '220px',
});

export const MenuData = ({ ...rest }) => {
    const items = navigation.map((i) => {
        return <MainMenuItem key={i.name} item={{ ...i, name: L(i.name) }} />;
    });

    return <Container {...rest}>{items}</Container>;
};
