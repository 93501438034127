import { PgBossJob } from '@app/api/models/PgBoss';
import styled from '@emotion/styled';
import React, { FC, useState } from 'react';
import { TableRowProps } from 'react-table';

type Props = TableRowProps & {
    data: PgBossJob['data'];
};

const Acordion = styled.pre({
    display: 'none',
    '&.expanded': {
        display: 'block',
    },
});
const JobRow: FC<Props> = ({ data, children, ...rest }) => {
    const [expanded, setExpanded] = useState(false);
    return (
        <div
            style={{
                display: 'grid',
            }}
            onClick={() => setExpanded((pre) => !pre)}
            className={'tr'}
        >
            <div {...rest}>{children}</div>
            <Acordion style={{ width: '100%' }} className={expanded ? 'expanded' : ''}>
                {JSON.stringify(data, null, 4)}
            </Acordion>
        </div>
    );
};

export default JobRow;
