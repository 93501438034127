import React from 'react';
import styled from '@emotion/styled';
import { Switch, Route } from 'react-router-dom';
import { Header } from '../../components/Layout/Header';
import { L } from '../../lib/i18n';
import SliderMenuProvider from '../../components/SliderMenu/context/SliderMenuContext';
import AddPageView from './components/AddPageView';
import EditPageItem from './components/EditPageItem';
import HelpItemList from './components/HelpItemList';

const GridContainer = styled.div({
    display: 'grid',
    gridTemplateAreas: `
    "PageList Main"
    "ThemeFeatures ThemeFeatures"
    `,
    gap: '32px',
    gridTemplateColumns: '340px 1fr',
});
const Main = styled.main({
    maxWidth: '100%',
});

export const HelpPage = () => {
    return (
        <SliderMenuProvider>
            <Header title={L('help_header')} />
            <GridContainer>
                <HelpItemList />
                <Main>
                    <Switch>
                        <Route exact path="/help/add">
                            <AddPageView />
                        </Route>
                        <Route exact path="/help/:id">
                            <EditPageItem />
                        </Route>
                    </Switch>
                </Main>
            </GridContainer>
        </SliderMenuProvider>
    );
};
