import { useMutation, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { L } from '../../../lib/i18n';
import { client } from '../../../lib/trpc';
import { projectQueryKeys } from './useGetProject';

const addMemberToProject = async (variables: { user_id: number; project_id: number; role: string }) => {
    return client.MembershipsApi.addMemberToProject.mutate(variables);
};

const useAddMemberToProject = () => {
    const queryClient = useQueryClient();
    return useMutation(
        (variables: { user_id: number; project_id: number; role: string }) => addMemberToProject(variables),
        {
            onError: (err) => {
                console.log(err);
                /* Shows error message */
                const error = typeof err === 'string' ? err : new Error(L('reset_error')).message;
                const message = error;
                toast.error(`${L(message)}`);
            },
            onSettled: () => {
                queryClient.invalidateQueries(projectQueryKeys.lists());
            },
        }
    );
};

const addMemberToProperty = async (variables: { user_id: number; property_id: number }) => {
    return client.MembershipsApi.addMemberToProperty.mutate(variables);
};

const useAddMemberToProperty = () => {
    const queryClient = useQueryClient();
    return useMutation((variables: { user_id: number; property_id: number }) => addMemberToProperty(variables), {
        onError: (err) => {
            /* Shows error message */
            const error = typeof err === 'string' ? err : new Error(L('reset_error')).message;
            const message = error;
            toast.error(`${L(message)}`);
        },
        onSettled: () => {
            queryClient.invalidateQueries(projectQueryKeys.lists());
        },
    });
};

export { useAddMemberToProject, useAddMemberToProperty };
