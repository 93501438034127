import React from 'react';
import { L } from '../../../lib/i18n';
import AreaCards from '../../../components/AreaCard/AreaCards/AreaCards';
import AreaCard from '../../../components/AreaCard/AreaCard';
import CardWithDivider from '../../../components/Layout/CardWithDivider';
import CardTitle from '../../../components/typography/CardTitle';
import { GetIconName } from '../functions/getIconName';
import StyledSwitch from '../../../components/StyledSwitch';
import { Loading } from '../../../components/Loading/Loading';
import { trpc } from '../../../lib/trpc';
import { Feature, ProjectFeatures } from '@app/api/models/ProjectFeatures';

type Props = { projectId: number };
export const FeaturesPage = ({ projectId }: Props) => {
    const utils = trpc.useContext();
    const features: Feature[] = [];

    const projectFeatures: ProjectFeatures[] = [];

    const { mutate: save } = trpc.ProjectFeturesApi.save.useMutation();
    const { mutate: remove } = trpc.ProjectFeturesApi.remove.useMutation();

    const onChange = (checked: boolean, featureId: number) => {
        checked
            ? remove(
                  { projectId, featureId },
                  {
                      onSuccess: () => {
                          utils.ProjectFeturesApi.listProjectFeatures.invalidate({ projectId });
                      },
                  }
              )
            : save(
                  { projectId, featureId },
                  {
                      onSuccess: () => {
                          utils.ProjectFeturesApi.listProjectFeatures.invalidate({ projectId });
                      },
                  }
              );
    };

    const featureIds = projectFeatures?.map((feature) => feature.feature_id);

    if (!features) return <Loading />;

    return (
        <CardWithDivider
            topArea={<CardTitle>{L('features_residence')}</CardTitle>}
            mainArea={
                <AreaCards>
                    {features
                        ?.filter((feature) => feature.is_feature_card)
                        .map((feature) => {
                            return (
                                <AreaCard
                                    Switch={
                                        <StyledSwitch
                                            checked={featureIds?.includes(feature.id)}
                                            onClick={(e) =>
                                                onChange(Boolean(e.currentTarget.ariaChecked === 'true'), feature.id)
                                            }
                                        />
                                    }
                                    key={feature.id}
                                    name={L(feature.name)}
                                    description={L(feature.name)}
                                    Icon={GetIconName(feature.name)}
                                />
                            );
                        })}
                </AreaCards>
            }
        />
    );
};
