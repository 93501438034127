import React, { ComponentPropsWithoutRef, FC, forwardRef } from 'react';
import { style } from '../Shared/Style';
import styled from '@emotion/styled';

const StyledInputField = styled.input({
    background: style.colors.inputBackground,
    border: `1px solid ${style.colors.inputBorder}`,
    width: '100%',
    height: '42px',
    borderRadius: style.roundCornerSize.small,
    outline: 'none',
    padding: style.margin.s,
    fontSize: style.fontSize.seta,
});

const Input = forwardRef<HTMLInputElement, ComponentPropsWithoutRef<'input'>>(({ ...rest }, ref) => {
    return <StyledInputField ref={ref} {...rest} />;
});

export default Input;
