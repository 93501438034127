import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { Icon } from '../../components/Icon/Icon';
import styled from '@emotion/styled';
import { style } from '../Shared/Style';
import { css } from '@emotion/core';

const MenuLink = styled(NavLink)({
    display: 'flex',
    color: '#AFAFAF',
    alignItems: 'center',
    padding: '20px 12px 20px 12px',
    borderLeft: '3px solid transparent',
    textDecoration: 'none',
    stroke: '#AFAFAF',
    '&.active': { background: '#F0F1F7', borderLeftColor: '#73af00', color: '#73af00' },
});

const Text = styled.div({
    marginLeft: style.margin.s,
});

const cssIcon = css({
    paddingRight: style.margin.s,
    display: 'inline-block',
    height: '24px',
    stroke: '#AFAFAF',
});

interface ItemProps {
    item: any;
}

export const MainMenuItem = (props: ItemProps) => {
    const NavIcon = Icon[props.item.icon] || Icon['Binder'];

    return (
        <MenuLink exact={props.item.exact} to={props.item.link}>
            <NavIcon css={[cssIcon]} /> <Text>{props.item.name}</Text>
        </MenuLink>
    );
};
