import React, { useState } from 'react';
import CardWithDivider from '../../../components/Layout/CardWithDivider';
import Table from '../../../components/Table/ReactTable/Table';
import CardTitle from '../../../components/typography/CardTitle';
import { L } from '../../../lib/i18n';
import { Loading } from '../../../components/Loading/Loading';
import { useModal } from '../../../components/Modal/Context/ModalContext';
import Modal from '../../../components/Modal/Modal';
import UploadHtml from '../UploadHtml/UploadHtml';
import { MailTemplateColumns } from './MailTemplateColumns';
import { trpc } from '../../../lib/trpc';
import { MailTemplate } from '@app/api/models/Settings';

const TemplatePage = () => {
    const { ToggleIsModalOpen } = useModal();
    const [activeTemplate, setActiveTempalte] = useState<MailTemplate>();
    const onClick = (template: MailTemplate) => {
        setActiveTempalte(template);
        ToggleIsModalOpen();
    };

    const { data } = trpc.SettingsApi.listMailTemplates.useQuery();

    if (!data) return <Loading />;
    return (
        <>
            {activeTemplate && (
                <Modal>
                    <UploadHtml template={activeTemplate} />
                </Modal>
            )}
            <CardWithDivider
                topArea={<CardTitle>{L('settings_mail_templates')}</CardTitle>}
                mainArea={<Table columns={MailTemplateColumns(onClick)} data={data} />}
            />
        </>
    );
};

export default TemplatePage;
