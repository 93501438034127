import styled from '../../../lib/styled';
import React from 'react';
import { FC } from 'react';
import { colors } from '../../Shared/Style/colors';
import Icon from '../ungroup-outline-16.svg';

const ThemedCopy = styled(Icon)(() => {
    return {
        height: '24px',
        width: '24px',
        '& g': {
            stroke: colors.icons,
        },
        '& path': {
            strokeWidth: '1px',
        },
    };
});
const Copy: FC = ({ ...rest }) => {
    return <ThemedCopy {...rest} />;
};

export default Copy;
