import React, { useEffect, useState } from 'react';
import { L } from '../../lib/i18n';
import { Dropdown } from '../../components/Dropdown/Dropdown';
import { Loading } from '../../components/Loading/Loading';
import PageTitle from '../../components/typography/PageTitle';
import { Redirect, Route, Switch } from 'react-router-dom';
import { FeaturesPage } from './pages/FeaturesPage';
import BigTabNav from '../../components/BigTabItem/BigTabNav';
import BigNavItem from '../../components/BigTabItem/BigTabListItemNav';
import styled from '@emotion/styled';
import InvoiceSettingsPage from './pages/InvoiceSettingsPage';
import CardWithDivider from '../../components/Layout/CardWithDivider';
import H2 from '../../components/typography/H2';
import CommunityPreview from './pages/CommunityPreview';
import { trpc } from '../../lib/trpc';
import ProjectDropdown from './components/ProjectDropdown';
import MailPage from './pages/CommunityMailPage';

const AboveCard = styled.div({
    display: 'grid',
    gridTemplateColumns: 'repeat(4,max-content)',
    gap: '1.5rem',
    alignItems: 'center',
});

const Wrapper = styled.span({
    backgroundColor: 'white',
});

const CardWrapper = styled.div({
    display: 'grid',
    gap: '1.5rem',
});

export const SettingsPage = () => {
    const [accountId, setAccountId] = useState<number>();

    const accountsQuery = trpc.ProjectFeturesApi.listAccounts.useQuery();

    const [projectId, setProjectId] = useState<number>();

    useEffect(() => {
        if (!accountsQuery.data) return;
        setAccountId(accountsQuery.data?.[0].id);
    }, [accountsQuery.data]);

    if (accountsQuery.status === 'error') <div>Kunde inte hämta accounts listan</div>;

    if (accountsQuery.status === 'success') {
        const accounts = accountsQuery.data;
        const accountOptions = accounts?.map((d) => ({ label: d.name, value: d.id }));
        return (
            <>
                <PageTitle>{L('settings')}</PageTitle>
                <AboveCard>
                    <p>{L('account')}</p>
                    <Wrapper>
                        <Dropdown
                            value={accountId}
                            onChange={(value) => setAccountId(value)}
                            options={accountOptions}
                        />
                    </Wrapper>
                    {accountId && (
                        <>
                            <p>{L('project')}</p>
                            <Wrapper>
                                <ProjectDropdown
                                    setProjectId={setProjectId}
                                    projectId={projectId}
                                    accountId={accountId}
                                />
                            </Wrapper>
                        </>
                    )}
                </AboveCard>

                <BigTabNav style={{ marginTop: '24px' }}>
                    <BigNavItem exact to={'/settings/feature'}>
                        {L('features')}
                    </BigNavItem>
                    <BigNavItem exact to={`/settings/invoice`}>
                        {L('invoice_information')}
                    </BigNavItem>
                    <BigNavItem exact to={'/settings/mail'}>
                        {L('mail')}
                    </BigNavItem>
                </BigTabNav>

                <Switch>
                    <Route exact path="/settings/">
                        <Redirect to="/settings/feature" />
                    </Route>
                    <Route path="/settings/feature">
                        {projectId ? (
                            <CardWrapper>
                                <FeaturesPage projectId={projectId} />
                                <CommunityPreview projectId={projectId} />
                            </CardWrapper>
                        ) : (
                            <CardWithDivider mainArea={<H2>{L('no_project')}</H2>} />
                        )}
                    </Route>
                    <Route path="/settings/invoice">
                        {projectId ? (
                            <InvoiceSettingsPage projectId={projectId} />
                        ) : (
                            <CardWithDivider mainArea={<H2>{L('no_project')}</H2>} />
                        )}
                    </Route>
                    <Route path="/settings/mail">
                        {projectId ? (
                            <MailPage projectId={projectId} />
                        ) : (
                            <CardWithDivider mainArea={<H2>{L('no_project')}</H2>} />
                        )}
                    </Route>
                </Switch>
            </>
        );
    }
    return <Loading />;
};
