import React from 'react';
import styled from '@emotion/styled';
import ItemList from './ItemList';

const Container = styled.div({
    marginTop: '2rem',
    marginBottom: '2rem',
    width: '100%',
    display: 'flex',
});

const PageList = ({ ...rest }) => {
    return (
        <Container {...rest}>
            <ItemList />
        </Container>
    );
};

export default PageList;
