import { Route } from 'react-router-dom';
import React from 'react';
import { L } from '../../lib/i18n';
import { MembershipPage } from './MembershipPage';

export const routes = [
    <Route key="1" path="/membership/:id" component={MembershipPage} />,
    <Route key="0" exact path="/membership" component={MembershipPage} />,
];

export const navigation = [{ name: L('members'), link: '/membership', icon: 'Contact' }];
