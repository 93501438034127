import styled from '@emotion/styled';
import React, { FC } from 'react';
import { colors } from '../../Shared/Style/colors';

type AvatarWithInitials = {
    name: string;
    url?: never;
    size?: 'lg';
};

type AvatarWithImage = {
    url: string;
    name?: never;
    size?: 'lg';
};

type AvatarProps = AvatarWithImage | AvatarWithInitials;

const Image = styled.div((props: { url?: string; size?: 'lg' }) => ({
    display: 'grid',
    placeItems: 'center',
    width: props.size === 'lg' ? '150px' : '40px',
    height: props.size === 'lg' ? '150px' : '40px',
    fontSize: props.size === 'lg' ? '80px' : undefined,
    backgroundColor: colors.avatarBackground,
    backgroundImage: `url(${props.url})`,
    borderRadius: '50%',
    backgroundSize: 'cover',
    backgroundRepeat: 'norepeat',
    backgroundPosition: 'center',
}));

const Initials = styled.span({
    textTransform: 'uppercase',
    lineHeight: 0,
    color: '#ffffff',
});

const getInitials = (name: string) => {
    const splitedName = name.split(' ');

    if (splitedName.length < 2) {
        return splitedName[0].substring(0, 2);
    }

    const firstInitial = splitedName[0].substring(0, 1);
    const secondInitial = splitedName[splitedName.length - 1].substring(0, 1);
    const initials = firstInitial + secondInitial;

    return initials;
};
const Avatar: FC<AvatarProps & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>> = ({
    url,
    name,
    ...rest
}) => {
    if (!url && !name) return <Image role="img" aria-label="avatar" {...rest} />;

    const initials = name ? getInitials(name) : '';
    return url ? (
        <Image role="img" aria-label="avatar" {...rest} url={url} />
    ) : (
        <Image role="img" aria-label="avatar" {...rest}>
            <Initials>{initials}</Initials>
        </Image>
    );
};

export default Avatar;
