import React from 'react';
import { StyleColumn } from '../../../types/ColumnType';
import styled from '@emotion/styled';
import { L } from '../../../lib/i18n';

import TrashCan from '../../../components/Icon/trash-simple.svg';
import triggerDirtyFieldsToast from '../../../components/Forms/DirtyFieldsToasts';
import useRemoveMembership from '../hooks/useRemoveMembership';

const DeleteIcon = styled(TrashCan)({
    width: '1rem',
    height: '1rem',
    cursor: 'pointer',
});

export const COLUMNSPROJECTS: StyleColumn<any>[] = [
    {
        Header: () => L('projectOrProperty'),
        accessor: 'name',
        width: 150,
        Cell: ({ value }) => {
            return <span>{value}</span>;
        },
    },
    {
        Header: () => L('type'),
        accessor: 'memberable_type',
        Cell: ({ value }) => {
            return <div>{L(`${value.toLowerCase()}`)}</div>;
        },
    },
    {
        Header: () => L('role'),
        accessor: 'role',
        Cell: ({ value }) => {
            return <div>{L(`${value}`)}</div>;
        },
    },
    {
        Header: () => L('settings'),
        accessor: 'project_id',
        style: {
            justifyContent: 'flex-end',
            alignItems: 'flex-end',
        },
        Cell: (value) => {
            const { mutate: removeMembership } = useRemoveMembership();
            const original = value.row.original;
            return (
                <DeleteIcon
                    onClick={() => {
                        return triggerDirtyFieldsToast({
                            title: L('delete_user_from_membership') + original.name,
                            continueButtonText: L('yes'),
                            continueFn: () => {
                                removeMembership({ membershipId: original.membership_id, name: original.name });
                            },
                        });
                    }}
                />
            );
        },
    },
];
