import { useQuery } from '@tanstack/react-query';
import { client } from '../../../lib/trpc';
import { projectQueryKeys } from '../../members/hooks/useGetProject';

const getProjectByProjectId = (id: number) => {
    return client.ProjectsApi.getProjectName.query({ projectId: id });
};

const useGetProjectByProjectId = (id: number) => {
    return useQuery({
        queryFn: ({ queryKey }) => getProjectByProjectId(queryKey[0].id),
        queryKey: projectQueryKeys.list(id),
        enabled: !!id,
    });
};

export { useGetProjectByProjectId };
