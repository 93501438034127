import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { HelpItem } from '@app/api/models/Help';
import { Button } from '../../../components/Buttons/Button';
import { useHistory, useParams } from 'react-router-dom';
import Label from '../../../components/Forms/Label';
import Input from '../../../components/Forms/Input';
import { L } from '../../../lib/i18n';
import StyledSwitch from '../../../components/StyledSwitch';
import { applicationsOptions } from './DropdownOptions';
import { Dropdown } from '../../../components/Dropdown/Dropdown';
import { trpc } from '../../../lib/trpc';
import Quill from '../../../components/Quill/Quill';

const Box = styled.div({
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '4px',
    marginBottom: '2rem',
});
const TopWrapper = styled.div({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    alignItems: 'center',
    justifyContent: 'space-between',
});
const SwitchWrapper = styled.div({
    display: 'flex',
    alignItems: 'center',
    justifySelf: 'end',
});
const BottomWrapper = styled.div({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginTop: '1rem',
    marginBottom: '2rem',
});
const Span = styled.span({
    paddingRight: '0.5rem',
});
const StyledButton = styled(Button)({
    marginLeft: '1rem',
});

const EditBlogItem = () => {
    const utils = trpc.useContext();
    const history = useHistory();
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const params = useParams<{ id: string }>();
    const { data: blogListItem } = trpc.BlogApi.get.useQuery({ id: +params.id });
    const { mutate: updateBlogListItem, status } = trpc.BlogApi.save.useMutation();
    const { mutate: updatePublish } = trpc.BlogApi.publish.useMutation();
    const { mutate: removeBlogItem } = trpc.BlogApi.remove.useMutation({
        onSuccess: () => {
            utils.BlogApi.listByApplication.invalidate();
            history.push('/blog');
        },
    });
    const [newApplication, setNewApplication] = useState<'' | HelpItem['application']>('');
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        if (!blogListItem) return;
        setTitle(blogListItem.title);
        setDescription(blogListItem.description);
        setNewApplication(blogListItem.application);
    }, [blogListItem]);

    if (!blogListItem) return null;

    const handleClick = () => {
        updatePublish(
            { id: blogListItem.id, publish: !blogListItem.published_at },
            {
                onSettled: () => {
                    utils.BlogApi.listByApplication.invalidate();
                    utils.BlogApi.get.invalidate({ id: +params.id });
                },
            }
        );
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (title.length <= 0) {
            setErrorMessage('Du måste fylla i en titel.');
            return;
        }
        if (description.length <= 11) {
            setErrorMessage('Du måste fylla i en beskrivning.');
            return;
        }
        if (!newApplication) return;
        if (title && description)
            updateBlogListItem({
                item: {
                    ...blogListItem,
                    title,
                    description,
                    application: newApplication,
                },
            });
    };

    return (
        <>
            <h1>{errorMessage && errorMessage}</h1>
            <form onSubmit={handleSubmit}>
                <Box>
                    <TopWrapper>
                        <Label title={L('title')}>
                            <Input
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                                placeholder={L('title') + '...'}
                            />
                        </Label>
                        <SwitchWrapper>
                            <Span>{L('publish')}</Span>
                            <StyledSwitch checked={blogListItem.published_at ? true : false} onClick={handleClick} />
                        </SwitchWrapper>
                    </TopWrapper>
                    <br />
                    <Label title={L('Applikation')} />
                    <Dropdown
                        value={newApplication}
                        onChange={(value) => setNewApplication(value)}
                        options={applicationsOptions}
                    />
                    <Label title={L('content')} />
                    <Quill
                        value={description}
                        onChange={(value) => setDescription(value)}
                        placeholder={L('write_something')}
                    />
                    <BottomWrapper>
                        <StyledButton
                            variant="delete"
                            type="button"
                            onClick={() => removeBlogItem({ id: blogListItem.id })}
                        >
                            {L('delete_page')}
                        </StyledButton>
                        <StyledButton variant="selectedMenu" type="submit">
                            {status === 'loading' ? L('loading') + '...' : L('save')}
                        </StyledButton>
                    </BottomWrapper>
                </Box>
            </form>
        </>
    );
};

export default EditBlogItem;
