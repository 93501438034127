import styled from '../../../lib/styled';
import React from 'react';
import { FC } from 'react';
import { colors } from '../../Shared/Style/colors';
import Icon from '../app-store.svg';

const ThemedAddon = styled(Icon)(() => {
    return {
        height: '24px',
        width: '24px',
        '& g': {
            stroke: colors.black,
        },
        '& path': {
            stroke: colors.black,
        },
        '& polyline': {
            stroke: colors.black,
        },
        '& line': {
            stroke: colors.black,
        },
    };
});
const Addon: FC = ({ ...rest }) => {
    return <ThemedAddon {...rest} />;
};

export default Addon;
