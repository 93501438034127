import { useMutation, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { L } from '../../../lib/i18n';
import { client } from '../../../lib/trpc';
import { memberQueryKeys } from './useGetUser';

const resetPassword = async (variables: { user_id: number; app: string }) => {
    return client.UsersApi.resetPassword.mutate(variables);
};

const useResetPassword = () => {
    const queryClient = useQueryClient();
    return useMutation((variables: { user_id: number; app: string }) => resetPassword(variables), {
        onError: (err) => {
            /* Shows error message */
            const error = typeof err === 'string' ? err : new Error(L('reset_error')).message;
            const message = error;
            toast.error(`${L(message)}`);
        },
        onSettled: () => {
            queryClient.invalidateQueries(memberQueryKeys.lists());
        },
    });
};

export default useResetPassword;
