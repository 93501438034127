// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2OQFVTtPg5r24gJtFcoUZD {\n  vertical-align: middle;\n  font-size: 0;\n}\n._2OQFVTtPg5r24gJtFcoUZD > svg {\n  width: 21px;\n  height: auto;\n  vertical-align: middle;\n}\n._2OQFVTtPg5r24gJtFcoUZD._3fdIgbrzxX4APJNQW9rY9E > svg {\n    width: 24px;\n}\n._2OQFVTtPg5r24gJtFcoUZD._3MFdjHHuyzX9dcm7h2QzfM > svg {\n  width: 12px;\n}\n._2OQFVTtPg5r24gJtFcoUZD.kO1iCbDnWXdYIWukT_ZOe > svg {\n  width: 48px;\n}\n._2OQFVTtPg5r24gJtFcoUZD.UpG2k53phOLndJMIYEKOH > svg {\n    width: 100px;\n}\n._1_8pCpSYC0SJb3h7e5_CIA {\n  cursor: pointer;\n  border: 0;\n  background: inherit;\n}\n\n._2OQFVTtPg5r24gJtFcoUZD [fill='#111111'] {\n  fill: #86AE43;\n}\n._2OQFVTtPg5r24gJtFcoUZD [stroke='#111111'] {\n  stroke: #86AE43;\n\n}\n._1wPMI9H_GrBAkEx4G-oLoM svg {\n  animation: _3z9WimWrc8lAY4X_k_2erd 3000ms linear infinite;\n}\n\n@keyframes _3z9WimWrc8lAY4X_k_2erd {\n  0% {\n    transform: rotate(0deg);\n  }\n  100% {\n    transform: rotate(360deg);\n  }\n}\n", ""]);
// Exports
exports.locals = {
	"svg": "_2OQFVTtPg5r24gJtFcoUZD",
	"size-mSmall": "_3fdIgbrzxX4APJNQW9rY9E",
	"size-small": "_3MFdjHHuyzX9dcm7h2QzfM",
	"size-large": "kO1iCbDnWXdYIWukT_ZOe",
	"size-100": "UpG2k53phOLndJMIYEKOH",
	"clickable": "_1_8pCpSYC0SJb3h7e5_CIA",
	"state-spin": "_1wPMI9H_GrBAkEx4G-oLoM",
	"spin": "_3z9WimWrc8lAY4X_k_2erd"
};
module.exports = exports;
