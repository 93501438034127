import React from 'react';
import CardWithDivider from '../../../components/Layout/CardWithDivider';
import { Loading } from '../../../components/Loading/Loading';
import { trpc } from '../../../lib/trpc';
import InvoiceForm from '../components/InvoiceForm';

type Props = {
    projectId: number;
};
const InvoiceSettingsPage = ({ projectId }: Props) => {
    const invoiceQuery = trpc.ProjectInvoiceApi.get.useQuery({ projectId });

    if (invoiceQuery.status === 'success') {
        return (
            <CardWithDivider
                topArea={'Fakturainställningar'}
                mainArea={<InvoiceForm InvoiceInformation={invoiceQuery.data} />}
            />
        );
    }

    if (invoiceQuery.status === 'loading') {
        return <CardWithDivider topArea={'Fakturainställningar'} mainArea={<Loading />} />;
    }

    return (
        <CardWithDivider topArea={'Fakturainställningar'} mainArea={<div>Kunde inte hämta fakturerings data</div>} />
    );
};

export default InvoiceSettingsPage;
