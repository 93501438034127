import React from 'react';
import CardWithDivider from '../../../components/Layout/CardWithDivider';
import { Loading } from '../../../components/Loading/Loading';
import { trpc } from '../../../lib/trpc';
import CommunityMailForm from '../components/CommunityMailForm';
import { L } from '../../../lib/i18n';

type Props = {
    projectId: number;
};
const CommunityMailPage = ({ projectId }: Props) => {
    const query = trpc.CommunityMailApi.get.useQuery({ projectId });

    if (query.status === 'success') {
        return (
            <CardWithDivider topArea={L('community_mail')} mainArea={<CommunityMailForm Information={query.data} />} />
        );
    }

    if (query.status === 'loading') {
        return <CardWithDivider topArea={L('community_mail')} mainArea={<Loading />} />;
    }

    return <CardWithDivider topArea={L('community_mail')} mainArea={<div>{L('failed_to_fetch')}</div>} />;
};

export default CommunityMailPage;
