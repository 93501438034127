import { trpc } from '../../../lib/trpc';

const useJobRetry = () => {
    const utils = trpc.useContext();
    return trpc.PgBossApi.retryJob.useMutation({
        onSettled: () => {
            utils.PgBossApi.getTasks.invalidate();
        },
    });
};

export default useJobRetry;
