import React from 'react';
import styled from '@emotion/styled';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import useDragEnd from '../hooks/useDragEnd';
import AddBlogButton from './AddBlogButton';
import NavItem from './NavItem';
import { L } from '../../../lib/i18n';
import { trpc } from '../../../lib/trpc';

const NavListWrapper = styled.div({
    width: '100%',
    display: 'flex',
    alignItems: 'left',
    flexDirection: 'column',
});
const NavList = styled.ul({
    display: 'flex',
    flexDirection: 'column',
    listStyle: 'none',
    margin: '0px',
    padding: '0px',
});

const PageItem = () => {
    const dragEndResidence = useDragEnd('residence');
    const dragEndCommunity = useDragEnd('community');
    const { data: residenceItems } = trpc.BlogApi.listByApplication.useQuery({ application: 'residence' });
    const { data: communityItems } = trpc.BlogApi.listByApplication.useQuery({ application: 'community' });

    return (
        <>
            <NavListWrapper>
                <AddBlogButton />
                <h1>RESIDENCE</h1>
                {residenceItems && residenceItems.length > 0 ? (
                    <>
                        <DragDropContext onDragEnd={(result, provided) => dragEndResidence({ result, provided })}>
                            <Droppable droppableId="linkItems">
                                {(provided) => (
                                    <NavList className="linkItems" {...provided.droppableProps} ref={provided.innerRef}>
                                        {residenceItems &&
                                            residenceItems.map((residenceItem, index) => (
                                                <Draggable
                                                    key={residenceItem.id + residenceItem.title}
                                                    draggableId={residenceItem.id + residenceItem.title}
                                                    index={index}
                                                >
                                                    {(provided) => (
                                                        <NavItem
                                                            blogItem={residenceItem}
                                                            innerRef={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                        />
                                                    )}
                                                </Draggable>
                                            ))}
                                        {provided.placeholder}
                                    </NavList>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </>
                ) : (
                    <>
                        <span>{L('no_blog_items')}</span>
                    </>
                )}

                <h1>COMMUNITY</h1>
                {communityItems && communityItems.length > 0 ? (
                    <>
                        <DragDropContext onDragEnd={(result, provided) => dragEndCommunity({ result, provided })}>
                            <Droppable droppableId="linkItems">
                                {(provided) => (
                                    <NavList className="linkItems" {...provided.droppableProps} ref={provided.innerRef}>
                                        {communityItems &&
                                            communityItems.map((communityItem, index) => (
                                                <Draggable
                                                    key={communityItem.id + communityItem.title}
                                                    draggableId={communityItem.id + communityItem.title}
                                                    index={index}
                                                >
                                                    {(provided) => (
                                                        <NavItem
                                                            blogItem={communityItem}
                                                            innerRef={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                        />
                                                    )}
                                                </Draggable>
                                            ))}
                                        {provided.placeholder}
                                    </NavList>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </>
                ) : (
                    <>
                        <span>{L('no_blog_items')}</span>
                    </>
                )}

                {/* <h1>PROPERTY MANAGEMENT</h1>
                {propertyManagementItems && propertyManagementItems.length > 0 ? (
                    <>
                        <DragDropContext
                            onDragEnd={(result, provided) => dragEndPropertyManagement({ result, provided })}
                        >
                            <Droppable droppableId="linkItems">
                                {(provided) => (
                                    <NavList className="linkItems" {...provided.droppableProps} ref={provided.innerRef}>
                                        {propertyManagementItems &&
                                            propertyManagementItems.map((propertyManagementItem, index) => (
                                                <Draggable
                                                    key={propertyManagementItem.id + propertyManagementItem.title}
                                                    draggableId={
                                                        propertyManagementItem.id + propertyManagementItem.title
                                                    }
                                                    index={index}
                                                >
                                                    {(provided) => (
                                                        <NavItem
                                                            blogItem={propertyManagementItem}
                                                            innerRef={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                        />
                                                    )}
                                                </Draggable>
                                            ))}
                                        {provided.placeholder}
                                    </NavList>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </>
                ) : (
                    <>
                        <span>{L('no_help_items')}</span>
                    </>
                )} */}
            </NavListWrapper>
        </>
    );
};

export default PageItem;
