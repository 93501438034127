import React from 'react';
import toast from 'react-hot-toast';
import { L } from '../../lib/i18n';
import { Button } from '../Buttons/Button';

type Args = {
    continueFn: () => void;
    continueButtonText: string;
    title: string;
};
const triggerDirtyFieldsToast = ({ continueFn, continueButtonText, title }: Args) =>
    toast(
        (t) => (
            <div>
                <p>{L(title)}</p>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Button
                        onClick={() => {
                            continueFn();
                            toast.dismiss(t.id);
                        }}
                    >
                        {continueButtonText}
                    </Button>
                    <Button variant="filledPrimary" onClick={() => toast.dismiss(t.id)}>
                        {L('no')}
                    </Button>
                </div>
            </div>
        ),
        { duration: Infinity }
    );

export default triggerDirtyFieldsToast;
