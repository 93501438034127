import React from 'react';
import { StyleColumn } from '../../../types/ColumnType';
import styled from '@emotion/styled';
import { L } from '../../../lib/i18n';

import TrashCan from '../../../components/Icon/trash-simple.svg';
import triggerDirtyFieldsToast from '../../../components/Forms/DirtyFieldsToasts';
import { Link } from 'react-router-dom';

const Span = styled.span({
    textAlign: 'left',
    fontSize: '14px',
});
const StyledNavLink = styled(Link)({
    textDecoration: 'none',
    color: 'inherit',
});
export const COLUMNSPROJECTS: StyleColumn<any>[] = [
    {
        Header: () => L('projectOrProperty'),
        accessor: 'name',
        width: 150,
        Cell: ({ row }) => {
            const project = row.original;
            return (
                <StyledNavLink to={`/properties/${project.id}`}>
                    <Span>{project.name}</Span>
                </StyledNavLink>
            );
        },
    },
    {
        Header: () => L('organisation'),
        accessor: 'accountname',
        width: 150,
        Cell: ({ value }) => {
            return <span>{value}</span>;
        },
    },
];
