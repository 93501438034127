import React, { ReactNode } from 'react';
import { HTMLAttributes } from 'react';
import styled from 'styled-components';
import { colors } from '../Shared/Style/colors';
import Divider from '../thread/divider/Divider';

const Container = styled.div({
    width: '100%',
    backgroundColor: 'white',
    borderRadius: '4px',
    border: `1px solid ${colors.divider}`,
    boxShadow: '0px 1px 14px rgba(0, 0, 0, 0.1)',
});

const TopArea = styled.section({
    padding: '22px 32px 22px 32px',
});
const MainArea = styled.main({
    padding: '22px 32px 22px 32px',
});

type Props = {
    mainArea: ReactNode;
    topArea?: ReactNode;
} & HTMLAttributes<HTMLDivElement>;
const CardWithDivider = ({ mainArea, topArea, ...rest }: Props) => {
    return (
        <Container {...rest}>
            {topArea && (
                <>
                    <TopArea className="top">{topArea}</TopArea>
                    <Divider width={100} margin={'0px'} />
                </>
            )}
            <MainArea className="main">{mainArea}</MainArea>
        </Container>
    );
};

export default CardWithDivider;
