import React from 'react';
import { Route } from 'react-router-dom';
import JobsPage from './pages/JobsPageHandler';

export const routes = [
    <Route key="0" path="/jobs">
        <JobsPage />
    </Route>,
];

export const navigation = [{ name: 'Jobs', link: '/jobs', icon: 'Work' }];
