import * as React from 'react';
import styled from '@emotion/styled';
import { style } from '../Shared/Style';
import { Avatar } from '../Avatar/Avatar';

interface BaseCssProps {
    children?: any;
    theme?: any;
}

interface MyProps extends BaseCssProps {
    background?: string;
    title?: string;
}

const Container = styled.div<MyProps>(() => ({
    backgroundColor: style.colors.headerBackground,
    paddingBottom: 0,
    display: 'flex',
    height: '50px',
    justifyContent: 'space-between',
    alignItems: 'center',
}));

export const Header = ({ ...rest }) => {
    return (
        <Container {...rest}>
            <Avatar css={{ marginLeft: 'auto' }} />
        </Container>
    );
};
