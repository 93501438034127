import { trpc } from '../../../lib/trpc';

const useSaveHelpItem = () => {
    const utils = trpc.useContext();

    return trpc.HelpApi.save.useMutation({
        onSuccess: (data) => {
            const helpItems = utils.HelpApi.listByApplication.getData(data.application);
            if (helpItems) {
                const helpItemIdex = helpItems.findIndex((item) => item.id === data.id);

                helpItems.splice(helpItemIdex, 1, data);
                utils.HelpApi.listByApplication.setData(data.application, [...helpItems]);
            }
        },
        onSettled: () => {
            utils.HelpApi.listByApplication.invalidate();
        },
    });
};

export default useSaveHelpItem;
