import { MailTemplate } from '@app/api/models/Settings';
import { StyleColumn } from '../../../types/ColumnType';

import React from 'react';
import styled from '@emotion/styled';
import { L } from '../../../lib/i18n';
import { Button } from '../../../components/Buttons/Button';

const Span = styled.span({
    textAlign: 'left',
});

export const MailTemplateColumns: (onClick: (value: MailTemplate) => void) => StyleColumn<MailTemplate>[] = (
    onClick: (value: MailTemplate) => void
) => {
    return [
        {
            Header: () => L('template_name'),
            accessor: 'template_name',
            width: 300,
            style: {
                justifyContent: 'left',
                alignItems: 'center',
            },
            Cell: ({ row }) => {
                const template = row.original;
                return <Span>{L(`${template.template_name}`) + ` - (${template.template_name})`}</Span>;
            },
        },
        {
            Header: () => L('edit'),
            accessor: 'id',
            width: 50,
            style: {
                justifyContent: 'left',
                alignItems: 'center',
            },
            Cell: ({ row }) => {
                return (
                    <>
                        <Button onClick={() => onClick(row.original)}>{L('edit')}</Button>
                    </>
                );
            },
        },
    ];
};
