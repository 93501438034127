import React from 'react';
import { HTMLAttributes } from 'react';
import { Switch, Route } from 'react-router-dom';
import styled from 'styled-components';
import BigTab from '../../../components/BigTab';
import { L } from '../../../lib/i18n';
import JobsCreatePage from './JobsCreatePage';
import JobsTablePage from './JobsTablePage';

const Nav = styled.nav({
    display: 'flex',
    gap: '1rem',
    marginBottom: '1.5rem',
});

const JobsPage = ({ ...rest }: HTMLAttributes<HTMLDivElement>) => {
    return (
        <div {...rest}>
            <Nav>
                <BigTab exact to={'/jobs'}>
                    {L('table')}
                </BigTab>
                <BigTab to={'/jobs/create'}>{L('create_new_job')}</BigTab>
            </Nav>
            <Switch>
                <Route exact path={'/jobs'}>
                    <JobsTablePage />
                </Route>
                <Route path={'/jobs/create'}>
                    <JobsCreatePage />
                </Route>
            </Switch>
        </div>
    );
};

export default JobsPage;
