import React, { HTMLAttributes } from 'react';
import styled from '@emotion/styled';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import HelpItem from './HelpItem';
import { L } from '../../../lib/i18n';
import { trpc } from '../../../lib/trpc';
import AddHelpItemLink from './AddHelpItemLink';
import useDragEnd from '../hooks/useDragEnd';

const NavListWrapper = styled.div({
    width: '100%',
    display: 'flex',
    alignItems: 'left',
    flexDirection: 'column',
});
const NavList = styled.ul({
    display: 'flex',
    flexDirection: 'column',
    listStyle: 'none',
    margin: '0px',
    padding: '0px',
});

const Container = styled.div({
    marginTop: '2rem',
    marginBottom: '2rem',
    width: '100%',
    display: 'flex',
});

const HelpItemList = ({ ...rest }: HTMLAttributes<HTMLDivElement>) => {
    const dragEndResidence = useDragEnd('residence');
    const dragEndCommunity = useDragEnd('community');

    const { data: residenceItemsItems } = trpc.HelpApi.listByApplication.useQuery('residence');
    const { data: communityItems } = trpc.HelpApi.listByApplication.useQuery('community');

    if (!residenceItemsItems || !communityItems) return null;
    return (
        <Container {...rest}>
            <NavListWrapper>
                <AddHelpItemLink />
                <h1>RESIDENCE</h1>
                {residenceItemsItems && residenceItemsItems.length > 0 ? (
                    <>
                        <DragDropContext onDragEnd={(result, provided) => dragEndResidence({ result, provided })}>
                            <Droppable droppableId="linkItems">
                                {(provided) => (
                                    <NavList className="linkItems" {...provided.droppableProps} ref={provided.innerRef}>
                                        {residenceItemsItems &&
                                            residenceItemsItems.map((residenceItem, index) => (
                                                <Draggable
                                                    key={residenceItem.id + residenceItem.title}
                                                    draggableId={residenceItem.id + residenceItem.title}
                                                    index={index}
                                                >
                                                    {(provided) => (
                                                        <HelpItem
                                                            helpItem={residenceItem}
                                                            innerRef={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                        />
                                                    )}
                                                </Draggable>
                                            ))}
                                        {provided.placeholder}
                                    </NavList>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </>
                ) : (
                    <>
                        <span>{L('no_help_items')}</span>
                    </>
                )}
                <h1>COMMUNITY</h1>
                {communityItems && communityItems.length > 0 ? (
                    <>
                        <DragDropContext onDragEnd={(result, provided) => dragEndCommunity({ result, provided })}>
                            <Droppable droppableId="linkItems">
                                {(provided) => (
                                    <NavList className="linkItems" {...provided.droppableProps} ref={provided.innerRef}>
                                        {communityItems &&
                                            communityItems.map((communityItem, index) => (
                                                <Draggable
                                                    key={communityItem.id + communityItem.title}
                                                    draggableId={communityItem.id + communityItem.title}
                                                    index={index}
                                                >
                                                    {(provided) => (
                                                        <HelpItem
                                                            helpItem={communityItem}
                                                            innerRef={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                        />
                                                    )}
                                                </Draggable>
                                            ))}
                                        {provided.placeholder}
                                    </NavList>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </>
                ) : (
                    <>
                        <span>{L('no_help_items')}</span>
                    </>
                )}
            </NavListWrapper>
        </Container>
    );
};

export default HelpItemList;
