import styled from '@emotion/styled';
import React from 'react';
import { Button } from '../../../components/Buttons/Button';
import { margin } from '../../../components/Shared/Style/margin';
import CardTitle from '../../../components/typography/CardTitle';
import { L } from '../../../lib/i18n';
import Spinner from '../../../components/Spinner/Spinner';
import { ListingProperty } from '@app/api/models/Properties';
import usePublishCheckedProperties from '../hooks/usePublishChecked';

const Wrapper = styled.div({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
});

const ButtonContainer = styled.div({
    width: 'auto',
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '1rem',
});
const TopArea = ({
    projectName,
    selectedProperties,
    onSubmit,
}: {
    projectName: string;
    selectedProperties: ListingProperty[];
    onSubmit: (value: boolean) => void;
}) => {
    const { mutate: publishAllChecked } = usePublishCheckedProperties();

    const publishChecked = () => {
        publishAllChecked({ propertiesArray: selectedProperties, publish: true });
        onSubmit(false);
    };
    const unpublishChecked = () => {
        publishAllChecked({ propertiesArray: selectedProperties, publish: false });
        onSubmit(false);
    };
    return (
        <Wrapper>
            <CardTitle>{projectName}</CardTitle>
            <div style={{ display: 'flex' }}>
                {selectedProperties.length === 0 ? (
                    <ButtonContainer>
                        <Button style={{ marginRight: margin.s }} disabled variant="secondary">
                            {L('publish_all_checked')}
                        </Button>
                        <Button style={{ marginRight: margin.s }} disabled variant="secondary">
                            {L('unpublish_all_checked')}
                        </Button>
                    </ButtonContainer>
                ) : (
                    <ButtonContainer>
                        <Button style={{ marginRight: margin.s }} variant="primary" onClick={() => publishChecked()}>
                            {L('publish_all_checked')}
                        </Button>
                        <Button style={{ marginRight: margin.s }} variant="primary" onClick={() => unpublishChecked()}>
                            {L('unpublish_all_checked')}
                        </Button>
                    </ButtonContainer>
                )}
            </div>
        </Wrapper>
    );
};

export default TopArea;
