import React, { useState } from 'react';
import CardWithDivider from '../../../components/Layout/CardWithDivider';
import CardTitle from '../../../components/typography/CardTitle';
import { L } from '../../../lib/i18n';
import { Loading } from '../../../components/Loading/Loading';
import Input from '../../../components/Forms/Input';
import Label from '../../../components/Forms/Label';
import styled from '@emotion/styled';
import Table from '../../../components/Table/ReactTable/Table';
import { MembershipsColumns } from './MembershipsColumns';
import { useGetMemberList } from '../hooks/useGetUser';
import EmptyState from '../../../components/EmptyState/EmptyState';
import { useAsyncDebounce } from 'react-table';

const StyledLabel = styled(Label)({
    width: '30%',
});

const Main = styled.div({
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    marginBottom: '1rem',
});

const StyledTable = styled(Table)({
    '& .table': {
        '& .tr': {
            gap: '3rem',
        },
    },
});

const MembershipCheckPage = () => {
    const [email, setEmail] = useState('');
    const [value, setValue] = useState('');
    const { data, status: userStatus, isFetching } = useGetMemberList(value);

    const onChange = useAsyncDebounce((value: string) => {
        setValue(value);
    }, 300);

    const table = () => {
        if (userStatus === 'success' && data.length > 0) {
            return <StyledTable columns={MembershipsColumns} data={data} />;
        }
        if (isFetching) {
            return <Loading />;
        }
        if (userStatus === 'error') {
            return (
                <EmptyState>
                    <EmptyState.Title>{`${L('no_user_title')} \"${value}\"`}</EmptyState.Title>
                    <EmptyState.Description>{L('no_user_description')}</EmptyState.Description>
                </EmptyState>
            );
        }
    };

    return (
        <>
            <CardWithDivider
                topArea={<CardTitle>{L('check_user')}</CardTitle>}
                mainArea={
                    <>
                        <Main>
                            <StyledLabel title="Sök användare">
                                <Input
                                    autoFocus
                                    onChange={(e) => {
                                        setEmail(e.target.value);
                                        onChange(e.target.value);
                                    }}
                                    value={email || ''}
                                    type="text"
                                />
                            </StyledLabel>
                            {data?.length === 0 && (
                                <EmptyState>
                                    <EmptyState.Title>{`${L('no_user_title')} \"${value}\"`}</EmptyState.Title>
                                    <EmptyState.Description>{L('no_user_description')}</EmptyState.Description>
                                </EmptyState>
                            )}
                        </Main>
                        {table()}
                    </>
                }
            />
        </>
    );
};

export default MembershipCheckPage;
