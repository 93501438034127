import { useQuery } from '@tanstack/react-query';
import { client } from '../../../lib/trpc';

export const projectQueryKeys = {
    all: [{ scope: 'project' }] as const,
    lists: () => [{ ...projectQueryKeys.all[0], entity: 'list' }] as const,
    list: (id: number) => [{ ...projectQueryKeys.lists()[0], id }] as const,
};

const getMembershipList = (id: number) => {
    return client.MembershipsApi.getMembershipsByUserId.query({ userId: id });
};

const useGetMembershipList = (id: number) => {
    return useQuery({
        queryFn: ({ queryKey }) => getMembershipList(queryKey[0].id),
        queryKey: projectQueryKeys.list(id),
        enabled: !!id,
    });
};

export { useGetMembershipList };
