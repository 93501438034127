import Document from './Document';
import Binder from './Binder';
import Calendar from './Calendar';
import Info from './Info';
import List from './List';
import Messages from './Messages';
import Property from './Property';
import WarningSign from './WarningSign';
import Addon from './Addon';

import Copy from './Copy';
import DigitalKey from './DigitalKey';
import Booking from './Booking';
import Ticket from './Ticket';

const ThemeIcons = {
    Document,
    Binder,
    Calendar,
    Info,
    List,
    Messages,
    Property,
    WarningSign,
    Copy,
    Addon,
    DigitalKey,
    Booking,
    Ticket,
};
export default ThemeIcons;
