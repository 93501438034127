import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { colors } from '../../../components/Shared/Style/colors';

const AddBlogButton = styled.div({
    fontSize: '1.5rem',
    color: 'white',
    backgroundColor: colors.plusButton,
    borderRadius: '50%',
    width: '30px',
    height: '30px',
    cursor: 'pointer',
});

const AddButtonWrapper = styled(Link)({
    display: 'flex',
    justifyContent: 'flex-end',
    textDecoration: 'none',
    marginBottom: '1rem',
});
const Plus = styled.span({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
});

const AddPageButton = ({ ...rest }) => {
    return (
        <AddButtonWrapper to="/blog/add" {...rest}>
            <AddBlogButton>
                <Plus>+</Plus>
            </AddBlogButton>
        </AddButtonWrapper>
    );
};

export default AddPageButton;
