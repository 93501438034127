import React from 'react';
import styled from '@emotion/styled';
import { Switch, SwitchProps } from '@rebass/forms';
import { colors } from '../Shared/Style/colors';

const SSwitch = styled(Switch)(({ theme }) => {
    return {
        cursor: 'pointer',
        borderColor: colors.black,
        '&[aria-checked=true]': {
            backgroundColor: colors.primary,
        },
        '& div': {
            backgroundColor: colors.background,
            borderColor: colors.black,
            borderWidth: '1px',
        },
        ':focus': {
            boxShadow: 'none',
        },
    };
});

export const StyledSwitch: React.FC<SwitchProps> = ({ ...rest }) => {
    return <SSwitch {...rest} />;
};

export default StyledSwitch;
