import { useQuery } from '@tanstack/react-query';
import { client } from '../../../lib/trpc';

export const organisationQueryKeys = {
    all: [{ scope: 'organisation' }] as const,
    organisationLists: () => [{ ...organisationQueryKeys.all[0], entity: 'organisationList' }] as const,
    organisationList: (id: number) => [{ ...organisationQueryKeys.organisationLists()[0], id }] as const,

    projectLists: () => [{ ...organisationQueryKeys.all[0], entity: 'projectList' }] as const,
    projectList: (id: number) => [{ ...organisationQueryKeys.projectLists()[0], id }] as const,

    propertyLists: () => [{ ...organisationQueryKeys.all[0], entity: 'propertyList' }] as const,
    propertyList: (id: number) => [{ ...organisationQueryKeys.propertyLists()[0], id }] as const,
};

const getOrganisation = () => {
    return client.ProjectFeturesApi.listAccounts.query();
};

const useGetOrganisations = () => {
    return useQuery({
        queryFn: () => getOrganisation(),
        queryKey: organisationQueryKeys.organisationLists(),
    });
};

const getOrganisationProjects = (id: number) => {
    return client.ProjectFeturesApi.listProjects.query({ accountId: id });
};

const useGetOrganisationProjects = (id: number) => {
    return useQuery({
        queryFn: ({ queryKey }) => getOrganisationProjects(queryKey[0].id),
        queryKey: organisationQueryKeys.projectList(id),
        enabled: !!id,
    });
};

const getOrganisationPropertiesByProject = (id: number) => {
    return client.PropertiesApi.listPropertiesByProject.query({ projectId: id });
};

const useGetOrganisationPropertiesByProject = (id: number) => {
    return useQuery({
        queryFn: ({ queryKey }) => getOrganisationPropertiesByProject(queryKey[0].id),
        queryKey: organisationQueryKeys.propertyList(id),
        enabled: !!id,
    });
};

export { useGetOrganisations, useGetOrganisationProjects, useGetOrganisationPropertiesByProject };
