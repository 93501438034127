import { MailTemplate } from '@app/api/models/Settings';
import styled from '@emotion/styled';
import React, { useState } from 'react';
import { Button } from '../../../components/Buttons/Button';
import Label from '../../../components/Forms/Label';
import { TextAreaInput } from '../../../components/Forms/TextArea';
import { useModal } from '../../../components/Modal/Context/ModalContext';
import { style } from '../../../components/Shared/Style';
import { colors } from '../../../components/Shared/Style/colors';
import Spinner from '../../../components/Spinner/Spinner';
import { L } from '../../../lib/i18n';
import { trpc } from '../../../lib/trpc';

const ButtonWraper = styled.div({
    padding: '1rem 0px 0px 0px',
    display: 'flex',
    width: '100%',
    gap: '1rem',
    justifyContent: 'space-between',
});

const Form = styled.form({
    width: '80%',
    maxHeight: '80vh',
    overflowY: 'auto',
    placeSelf: 'center',
    backgroundColor: colors.white,
    padding: '2rem',
    borderRadius: style.roundCornerSize.small,
});

type Props = {
    template: MailTemplate;
};

const UploadHtml = ({ template }: Props) => {
    const { mutate: save, status } = trpc.SettingsApi.saveMailTemplate.useMutation();
    const { ToggleIsModalOpen } = useModal();
    const utils = trpc.useContext();

    const [data, setData] = useState<string>(template.data || '');

    return (
        <Form
            onClick={(e) => {
                e.stopPropagation();
            }}
            onSubmit={(e) => {
                e.preventDefault();
                if (!template) return;
                save(
                    {
                        data,
                        id: template.id ?? undefined,
                        mail_template_id: template.mail_template_id,
                        name: template.template_name,
                    },
                    {
                        onSuccess: () => {
                            utils.SettingsApi.listMailTemplates.invalidate();
                        },
                    }
                );
            }}
        >
            <Label title={L('paste_html')}>
                <TextAreaInput
                    style={{ width: '100%' }}
                    value={data}
                    rows={25}
                    onChange={(e) => setData(e.target.value)}
                />
            </Label>

            <ButtonWraper>
                <Button type="button" onClick={() => ToggleIsModalOpen()} variant="filledPrimary">
                    Avbryt
                </Button>
                {status === 'idle' && (
                    <Button type={'submit'} variant="filledPrimary">
                        {L('save')}
                    </Button>
                )}
                {status === 'loading' && (
                    <Button variant="filledPrimary" style={{ display: 'flex', justifyContent: 'center' }}>
                        <Spinner />
                    </Button>
                )}
                {status === 'error' && (
                    <Button type={'submit'} variant="filledPrimary">
                        {L('error')}
                    </Button>
                )}
                {status === 'success' && (
                    <Button type={'submit'} variant="filledPrimary">
                        {L('saved')}
                    </Button>
                )}
            </ButtonWraper>
        </Form>
    );
};

export default UploadHtml;
