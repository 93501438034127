import { ProjectInvoice } from '@app/api/models/ProjectInvoice';
import styled from '@emotion/styled';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import React from 'react';
import toast from 'react-hot-toast';
import { Button } from '../../../components/Buttons/Button';
import Input from '../../../components/Forms/Input';
import Label from '../../../components/Forms/Label';
import { TextAreaInput } from '../../../components/Forms/TextArea';
import { style } from '../../../components/Shared/Style';
import { L } from '../../../lib/i18n';
import { trpc } from '../../../lib/trpc';

const Forms = styled.form({
    width: '100%',
    display: 'grid',
    alignSelf: 'center',
    paddingTop: style.margin.m,
    paddingBottom: style.margin.l,
    [`@media screen and (min-width: ${650}px)`]: {
        gridTemplateColumns: 'repeat(2, 1fr)',
        gap: `0px clamp(1rem, 2.5vw, 1.5rem)`,
    },
});

const InvoiceForm = ({ InvoiceInformation }: { InvoiceInformation: ProjectInvoice }) => {
    const { mutate: save } = trpc.ProjectInvoiceApi.save.useMutation();
    const formik = useFormik({
        initialValues: {
            expire_date: InvoiceInformation.expire_date || dayjs().format('YYYY-MM-DD'),
            invoice_address: InvoiceInformation.invoice_address || '',
            project_id: InvoiceInformation.project_id,
            invoice_city: InvoiceInformation.invoice_city || '',
            invoice_info: InvoiceInformation.invoice_info || '',
            invoice_zip: InvoiceInformation.invoice_zip || '',
            org_name: InvoiceInformation.org_name || '',
            org_number: InvoiceInformation.org_number || '',
            phone_number: InvoiceInformation.phone_number || '',
            start_date: InvoiceInformation.start_date || dayjs().format('YYYY-MM-DD'),
            subscription_info: InvoiceInformation.subscription_info || '',
        },
        onSubmit: (values: ProjectInvoice) => {
            save(
                {
                    ...values,
                },
                {
                    onSuccess: () => {
                        toast.success('Lyckades spara informationen');
                    },
                    onError: () => {
                        toast.error('Det gick inte att spara');
                    },
                }
            );
        },
    });

    return (
        <Forms onSubmit={formik.handleSubmit}>
            <Label title={L('Orginisationsnamn')}>
                <Input value={formik.values.org_name} onChange={formik.handleChange} id="org_name" name="org_name" />
            </Label>
            <Label title={L('Orginisationsnummer')}>
                <Input
                    value={formik.values.org_number}
                    onChange={formik.handleChange}
                    id="org_number"
                    name="org_number"
                />
            </Label>
            <Label title={L('Faktueringsadress')}>
                <Input
                    value={formik.values.invoice_address}
                    onChange={formik.handleChange}
                    id="invoice_address"
                    name="invoice_address"
                />
            </Label>
            <Label title={L('phone_number')}>
                <Input
                    value={formik.values.phone_number}
                    onChange={formik.handleChange}
                    id="phone_number"
                    name="phone_number"
                />
            </Label>
            <Label title={L('postnummer')}>
                <Input
                    value={formik.values.invoice_zip}
                    onChange={formik.handleChange}
                    id="invoice_zip"
                    name="invoice_zip"
                />
            </Label>
            <Label title={L('postort')}>
                <Input
                    value={formik.values.invoice_city}
                    onChange={formik.handleChange}
                    id="invoice_city"
                    name="invoice_city"
                />
            </Label>
            <Label title={L('Elektronisk fakturering')}>
                <TextAreaInput
                    value={formik.values.invoice_info}
                    onChange={formik.handleChange}
                    id="invoice_info"
                    name="invoice_info"
                />
            </Label>
            <div />
            <Label title={L('Abonnemangsvillkor')}>
                <Input
                    value={formik.values.subscription_info}
                    onChange={formik.handleChange}
                    id="subscription_info"
                    name="subscription_info"
                />
            </Label>
            <div />
            <Label title={L('startdatum')}>
                <input
                    type="date"
                    value={dayjs(formik.values.start_date).format('YYYY-MM-DD')}
                    onChange={formik.handleChange}
                    id="start_date"
                    name="start_date"
                />
            </Label>
            <div />
            <Label title={L('main_expire_date')}>
                <input
                    type="date"
                    value={dayjs(formik.values.expire_date).format('YYYY-MM-DD')}
                    onChange={formik.handleChange}
                    id="expire_date"
                    name="expire_date"
                />
            </Label>
            <div />
            <div style={{ marginTop: style.margin.m }}>
                <Button type="submit" variant="selectedMenu">
                    {L('save')}
                </Button>
            </div>
        </Forms>
    );
};

export default InvoiceForm;
