import styled from '@emotion/styled';
import React from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';
import { colors } from '../Shared/Style/colors';

const StyledNavLink = styled(NavLink)(() => {
    return {
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '1.125rem',
        letterSpacing: '0.41px',
        color: colors.tabInactive,
        boxSizing: 'border-box',
        display: 'inline-block',
        padding: '0px 0px 0.5rem 0px',
        textDecoration: 'none',

        '&:hover': {
            color: colors.tabActive,
            cursor: 'pointer',
        },
        '&.active': {
            color: colors.tabActive,
            textDecoration: 'none',
            borderBottom: `1px solid ${colors?.tabActive}`,
        },
    };
});

const BigTab: React.FC<NavLinkProps> = ({ children, ...rest }) => {
    return <StyledNavLink {...rest}>{children}</StyledNavLink>;
};

export default BigTab;
