import React from 'react';
import { FC } from 'react';
import styled from '../../../lib/styled';
import { colors } from '../../Shared/Style/colors';
import Icon from '../digital-key.svg';

const ThemedDigitalKey = styled(Icon)(() => {
    return {
        height: '24px',
        width: '24px',
        '& g': {
            stroke: colors.icons,
            strokeWidth: '1px',
        },
        '& path': {
            strokeWidth: '1px',
            stroke: colors.icons,
        },
        '& polyline': {
            strokeWidth: '1px',
            stroke: colors.icons,
        },
        '& line': {
            strokeWidth: '1px',
            stroke: colors.icons,
        },
    };
});
const DigitalKey: FC = ({ ...rest }) => {
    return <ThemedDigitalKey {...rest} />;
};

export default DigitalKey;
