import React, { useState } from 'react';
import CardWithDivider from '../../../components/Layout/CardWithDivider';
import CardTitle from '../../../components/typography/CardTitle';
import { L } from '../../../lib/i18n';
import { useParams } from 'react-router';
import styled from '@emotion/styled';
import { Button } from '../../../components/Buttons/Button';
import { Loading } from '../../../components/Loading/Loading';
import { useGetMemberById } from '../hooks/useGetUser';
import { COLUMNSPROJECTS } from './ColumnsProjects';
import { useGetMembershipList } from '../hooks/useGetProject';
import Table from '../../../components/Table/ReactTable/Table';
import AddNewMembershipForm from '../AddNewMembership/AddNewMembershipForm';
import * as Dialog from '@radix-ui/react-dialog';
import ChangePasswordForm from '../ChangePassword/ChangePasswordForm';
import EmptyState from '../../../components/EmptyState/EmptyState';
import ChangeEmailForm from '../ChangeEmail/ChangeEmailForm';

const ButtonContainer = styled.div({
    width: 'auto',
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '1rem',
});

const Wrapper = styled.div({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
});
const StyledTable = styled(Table)({
    '& .table': {
        '& .tr': {
            gap: '3rem',
        },
    },
});

const Overlay = styled(Dialog.Overlay)({
    background: 'rgba(0 0 0 / 0.5)',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'grid',
    placeItems: 'center',
    overflowY: 'auto',
});

const Content = styled(Dialog.Content)({
    minWidth: 300,
});

const MemberDetailPage = () => {
    const { id } = useParams<{ id: string }>();
    const [openAddUser, setAddUserOpen] = useState(false);
    const [openChangePassword, setChangePasswordOpen] = useState(false);
    const [openChangeEmail, setChangeEmailOpen] = useState(false);

    const { data: member, status: memberStatus } = useGetMemberById(id);

    const { data: projectList, status: projectListStatus, isFetching } = useGetMembershipList(+id);

    const handleModal = () => {
        setAddUserOpen(!openAddUser);
    };

    const handleChangePasswordModal = () => {
        setChangePasswordOpen(!openChangePassword);
    };

    const handleChangeEmailModal = () => {
        setChangeEmailOpen(!openChangeEmail);
    };

    const table = () => {
        if (projectListStatus === 'success' && projectList?.length > 0) {
            return <StyledTable columns={COLUMNSPROJECTS} data={projectList} />;
        }
        if (isFetching) {
            return <Loading />;
        }
        if (projectListStatus === 'error') {
            return (
                <EmptyState>
                    <EmptyState.Title>{L('no_memberships_title')}</EmptyState.Title>
                    <EmptyState.Description>{L('no_memberships_description')}</EmptyState.Description>
                </EmptyState>
            );
        }
    };

    if (memberStatus === 'success') {
        return (
            <>
                <Dialog.Root open={!!openAddUser}>
                    <Dialog.Portal>
                        <Overlay>
                            <Content>
                                <AddNewMembershipForm handleModal={handleModal} />
                            </Content>
                        </Overlay>
                    </Dialog.Portal>
                </Dialog.Root>
                <Dialog.Root open={!!openChangePassword}>
                    <Dialog.Portal>
                        <Overlay>
                            <Content>
                                <ChangePasswordForm handleModal={handleChangePasswordModal} />
                            </Content>
                        </Overlay>
                    </Dialog.Portal>
                </Dialog.Root>
                <Dialog.Root open={!!openChangeEmail}>
                    <Dialog.Portal>
                        <Overlay>
                            <Content>
                                <ChangeEmailForm handleModal={handleChangeEmailModal} />
                            </Content>
                        </Overlay>
                    </Dialog.Portal>
                </Dialog.Root>
                <CardWithDivider
                    topArea={
                        <Wrapper>
                            <CardTitle>{member?.name ? member.name : member.email}</CardTitle>
                            <ButtonContainer>
                                <Button onClick={handleChangeEmailModal} variant={'primary'}>
                                    {L('change_email')}
                                </Button>
                                <Button onClick={handleModal} variant={'primary'}>
                                    {L('add_to_membership')}
                                </Button>
                                <Button onClick={handleChangePasswordModal} variant={'primary'}>
                                    {L('reset_password')}
                                </Button>
                            </ButtonContainer>
                        </Wrapper>
                    }
                    mainArea={table()}
                />
            </>
        );
    }
    if (memberStatus === 'loading') return <Loading />;

    return <div>No member</div>;
};

export default MemberDetailPage;
