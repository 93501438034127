import React from 'react';
import { L } from '../../lib/i18n';

import PageTitle from '../../components/typography/PageTitle';
import { Redirect, Route, Switch } from 'react-router-dom';
import BigTabNav from '../../components/BigTabItem/BigTabNav';
import BigNavItem from '../../components/BigTabItem/BigTabListItemNav';
import { MessagePage } from './pages/MessagePage';

export const SigningPage = () => {
    return (
        <>
            <PageTitle>{L('signing')}</PageTitle>

            <BigTabNav style={{ marginTop: '24px' }}>
                <BigNavItem exact to={'/signing/message'}>
                    Signeringsmeddelande
                </BigNavItem>
            </BigTabNav>

            <Switch>
                <Route exact path="/signing/">
                    <Redirect to="/signing/message" />
                </Route>

                <Route exact path="/signing/message">
                    <MessagePage />
                </Route>
            </Switch>
        </>
    );
};
