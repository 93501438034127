import React, { useEffect, useState } from 'react';
import { L } from '../../../lib/i18n';
import CardWithDivider from '../../../components/Layout/CardWithDivider';
import CardTitle from '../../../components/typography/CardTitle';
import { Loading } from '../../../components/Loading/Loading';
import { Button } from '../../../components/Buttons/Button';
import Label from '../../../components/Forms/Label';
import { TextAreaInput } from '../../../components/Forms/TextArea';
import { trpc } from '../../../lib/trpc';
import toast from 'react-hot-toast';

export const MessagePage = () => {
    const [signingMessage, setMessage] = useState<string>('');
    const messageQuery = trpc.SigningApi.getMessage.useQuery();
    const { mutate: saveMessage } = trpc.SigningApi.saveMessage.useMutation();

    useEffect(() => {
        if (messageQuery.data?.message) setMessage(messageQuery.data?.message);
    }, [messageQuery.data]);

    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        saveMessage(
            { message: signingMessage, type: 'bankid' },
            {
                onSuccess: () => {
                    toast.success('Lyckades spara informationen');
                },
                onError: () => {
                    toast.error('Det gick inte att spara');
                },
            }
        );
    };

    if (messageQuery.status === 'error') return <div>{L('error')}</div>;
    if (messageQuery.status === 'success') {
        return (
            <CardWithDivider
                topArea={<CardTitle>{L('features')}</CardTitle>}
                mainArea={
                    <form onSubmit={(e) => onSubmit(e)}>
                        <Label title="BankId Meddelande">
                            <TextAreaInput onChange={(e) => setMessage(e.currentTarget.value)} value={signingMessage} />
                        </Label>
                        <Button>Save</Button>
                    </form>
                }
            />
        );
    }
    return <Loading />;
};
