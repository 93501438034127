import styled from '../../../lib/styled';
import React from 'react';
import { FC } from 'react';
import { colors } from '../../Shared/Style/colors';
import Icon from '../list.svg';

const ThemedList = styled(Icon)(() => {
    return {
        height: '24px',
        width: '24px',
        '& g': {
            stroke: colors.icons,
            strokeWidth: '3px',
        },
        '& circle': {
            stroke: colors.icons,
        },
        '& rect': {
            stroke: colors.icons,
        },
        '& line': {
            stroke: colors.icons,
        },
        '& polyline': {
            stroke: colors.icons,
        },
    };
});
const List: FC = ({ ...rest }) => {
    return <ThemedList {...rest} />;
};

export default List;
