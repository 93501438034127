import { PgBossJob } from '@app/api/public/PgBossApi';
import React from 'react';
import toast from 'react-hot-toast';
import { Button } from '../../../components/Buttons/Button';
import { L } from '../../../lib/i18n';
import useJobRetry from '../hooks/useJobRetry';

const RetryJob = ({ id, state }: PgBossJob) => {
    const { mutate } = useJobRetry();

    const handleClick = () => {
        mutate(
            { jobId: id, state: state },
            {
                onError: (error) => {
                    if (!(error instanceof Error)) return;
                    toast(error.message);
                },
            }
        );
    };
    return (
        <Button variant={'primary'} onClick={handleClick}>
            {L('retry_job')}
        </Button>
    );
};

export default RetryJob;
