import styled from '../../../lib/styled';
import React from 'react';
import { FC } from 'react';
import { colors } from '../../Shared/Style/colors';
import Icon from '../c-info.svg';

const ThemedInfo = styled(Icon)(() => {
    return {
        height: '24px',
        width: '24px',
        '& g': {
            stroke: colors.icons,
        },
        '& path': {
            stroke: colors.icons,
        },
        '& polyline': {
            stroke: colors.icons,
        },
        '& line': {
            stroke: colors.icons,
        },
        '& circle': {
            stroke: colors.icons,
            // fill: theme?.colors?.primary || colors.primary,
        },
        '& circle:first-of-type': {
            stroke: colors.icons,
            fill: colors.icons,
        },
    };
});
const Info: FC = ({ ...rest }) => {
    return <ThemedInfo {...rest} />;
};

export default Info;
