import React, { useState, useEffect, FormEvent } from 'react';
import styled from '@emotion/styled';
import StyledSwitch from '../../../components/StyledSwitch';
import { useHistory, useParams } from 'react-router-dom';
import { Button } from '../../../components/Buttons/Button';
import Quill from '../../../components/Quill/Quill';
import { L } from '../../../lib/i18n';
import Input from '../../../components/Forms/Input';
import Label from '../../../components/Forms/Label';
import { Dropdown } from '../../../components/Dropdown/Dropdown';
import {
    applicationsOptions,
    communityOptions,
    propertyManagementOptions,
    residenceOptions,
} from '../lib/DropdownOptions';
import { HelpItem } from '@app/api/models/Help';
import { trpc } from '../../../lib/trpc';
import useSaveHelpItem from '../hooks/useSaveHelpItem';

const Box = styled.div({
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '4px',
    marginBottom: '2rem',
});
const TopWrapper = styled.div({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    alignItems: 'center',
    justifyContent: 'space-between',
});
const SwitchWrapper = styled.div({
    display: 'flex',
    alignItems: 'center',
    justifySelf: 'end',
});
const BottomWrapper = styled.div({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginTop: '1rem',
    marginBottom: '2rem',
});
const Span = styled.span({
    paddingRight: '0.5rem',
});
const StyledButton = styled(Button)({
    marginLeft: '1rem',
});

const EditPageItem = () => {
    const [title, setTitle] = useState('');
    const utils = trpc.useContext();
    const history = useHistory();
    const { id } = useParams<{ id: string }>();
    const [pageName, setPageName] = useState('');
    const [description, setDescription] = useState('');
    const { data: pageListItem } = trpc.HelpApi.get.useQuery(+id);
    const { mutate: publish } = trpc.HelpApi.publish.useMutation();
    const { mutate: saveHelpItem, status } = useSaveHelpItem();
    const { mutate: remove } = trpc.HelpApi.remove.useMutation({
        onSuccess: () => {
            utils.HelpApi.listByApplication.invalidate();
            history.push('/help');
        },
    });
    const [newApplication, setNewApplication] = useState<'' | HelpItem['application']>('');
    const { data: residenceItems } = trpc.HelpApi.listByApplication.useQuery('residence');
    const { data: communityItems } = trpc.HelpApi.listByApplication.useQuery('community');
    const { data: propertyManagementItems } = trpc.HelpApi.listByApplication.useQuery('property_management');
    const [error, setError] = useState({
        title: '',
        description: '',
        pageName: '',
    });

    const avalibleCommunityOptions = [...communityOptions].filter(
        (option) =>
            !communityItems?.find(
                (item) =>
                    item.page_name === option.value &&
                    item.application === 'community' &&
                    pageListItem?.page_name !== option.value
            )
    );

    const avalibleResidenceOptions = [...residenceOptions].filter(
        (option) =>
            !residenceItems?.find(
                (item) =>
                    item.page_name === option.value &&
                    item.application === 'residence' &&
                    pageListItem?.page_name !== option.value
            )
    );

    const avaliblePropertyManagementOptions = [...propertyManagementOptions].filter(
        (option) =>
            !propertyManagementItems?.find(
                (item) =>
                    item.page_name === option.value &&
                    item.application === 'property_management' &&
                    pageListItem?.page_name !== option.value
            )
    );
    useEffect(() => {
        if (!pageListItem) return;
        setTitle(pageListItem.title);
        setDescription(pageListItem.description);
        setNewApplication(pageListItem.application);
        setPageName(pageListItem.page_name);
    }, [pageListItem]);

    if (!pageListItem) return null;

    const handleClick = () => {
        publish(
            { id: pageListItem.id, publish: !pageListItem.published_at },
            {
                onSettled: () => {
                    utils.HelpApi.listByApplication.invalidate();
                    utils.HelpApi.get.invalidate();
                },
            }
        );
    };

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (title.length <= 0) {
            setError((prev) => ({ ...prev, title: 'Du måste fylla i en titel.' }));
            return;
        }
        if (description.length <= 11) {
            setError((prev) => ({ ...prev, description: 'Du måste fylla i en beskrivning.' }));
            return;
        }
        if (pageName.length <= 0) {
            setError((prev) => ({ ...prev, pageName: 'Du måste välja en sida.' }));
            return;
        }
        if (!newApplication) return;
        if (title && description && pageName)
            saveHelpItem({
                id: pageListItem.id,
                title,
                description,
                page_name: pageName,
                application: newApplication,
            });
    };

    return (
        <>
            <form onSubmit={handleSubmit}>
                <Box>
                    <TopWrapper>
                        <Label title={L('title')} error={error.title}>
                            <Input
                                value={title}
                                onChange={(e) => {
                                    setTitle(e.target.value);
                                }}
                                placeholder={L('title') + '...'}
                            />
                        </Label>
                        <SwitchWrapper>
                            <Span>{L('publish')}</Span>
                            <StyledSwitch checked={pageListItem.published_at ? true : false} onClick={handleClick} />
                        </SwitchWrapper>
                    </TopWrapper>
                    <br />
                    <Label title={L('Applikation')} />
                    <Dropdown
                        value={newApplication}
                        onChange={(value) => {
                            setNewApplication(value);
                            setPageName('');
                        }}
                        options={applicationsOptions}
                    />
                    <Label title={L('Sidonamn')} error={error.pageName} />
                    {newApplication === 'community' && (
                        <Dropdown
                            value={pageName}
                            onChange={(value) => setPageName(value)}
                            options={avalibleCommunityOptions}
                        />
                    )}
                    {newApplication === 'residence' && (
                        <Dropdown
                            value={pageName}
                            onChange={(value) => setPageName(value)}
                            options={avalibleResidenceOptions}
                        />
                    )}
                    {newApplication === 'property_management' && (
                        <Dropdown
                            value={pageName}
                            onChange={(value) => setPageName(value)}
                            options={avaliblePropertyManagementOptions}
                        />
                    )}
                    <Label title={L('content')} error={error.description} />
                    <Quill
                        value={description}
                        onChange={(value) => {
                            setDescription(value);
                        }}
                    />

                    <BottomWrapper>
                        <StyledButton type="button" variant="delete" onClick={() => remove(pageListItem.id)}>
                            {L('delete_page')}
                        </StyledButton>
                        <StyledButton variant="selectedMenu" type="submit">
                            {status === 'loading' ? L('loading') + '...' : L('save')}
                        </StyledButton>
                    </BottomWrapper>
                </Box>
            </form>
        </>
    );
};

export default EditPageItem;
