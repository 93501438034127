import React from 'react';
import ReactQuill from 'react-quill';
const modules = {
    toolbar: [
        [{ header: [1, 2, 3, false] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['link'],
        ['image'],
        ['video'],
    ],
};
const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'image',
    'link',
    'video',
];
const NoInlineStyleQuill = ({ ...rest }: React.ComponentProps<typeof ReactQuill>) => {
    return <ReactQuill modules={modules} formats={formats} {...rest} placeholder="Skriv något här..." />;
};
export default NoInlineStyleQuill;
