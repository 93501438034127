import { BlogItem } from '@app/api/public/BlogApi';
import { ResponderProvided, DropResult } from 'react-beautiful-dnd';
import { trpc } from '../../../lib/trpc';

type UseDragVariables = { result: DropResult; provided: ResponderProvided };

export const sortList = (result: DropResult, listItems: BlogItem[]) => {
    if (!result || !result.destination) return;
    const items = [...listItems];
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    return {
        items,
        reorderedItem,
        index: result.destination.index,
    };
};

const dragEnd = ({ result }: UseDragVariables, listItems?: BlogItem[]) => {
    if (!listItems) return;
    const sortedList = sortList(result, listItems);
    if (!sortedList) return;
    return sortedList.items.map((item, index) => {
        return { id: item.id, sort: index };
    });
};

const useDragEnd = (application: BlogItem['application']) => {
    const utils = trpc.useContext();

    const blogItemsQuery = trpc.BlogApi.listByApplication.useQuery({ application });

    const { mutate } = trpc.BlogApi.updateSortOrder.useMutation({
        onMutate: (variables) => {
            utils.BlogApi.listByApplication.cancel({ application });
            const blogItems = utils.BlogApi.listByApplication.getData({ application });

            if (!blogItems) return;
            blogItems?.forEach((item) => {
                const newSortItem = variables.find((variable) => variable.id === item.id);
                if (newSortItem) {
                    item.sort = newSortItem.sort;
                }
            });

            blogItems.sort((a, b) => a.sort - b.sort);
            utils.BlogApi.listByApplication.setData({ application }, blogItems);
        },
        onSettled: () => {
            utils.BlogApi.listByApplication.invalidate({ application });
        },
    });

    return async (variables: UseDragVariables) => {
        if (!blogItemsQuery.data) return;
        const items = dragEnd(variables, blogItemsQuery.data);
        if (!items) return;
        mutate(items);
    };
};

export default useDragEnd;
