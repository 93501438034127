import React, { createContext, FC, useContext, useState } from 'react';

type ModalContextType = {
    isModalOpen: boolean;
    ToggleIsModalOpen: () => void;
};

const ModalContext = createContext({} as ModalContextType);

const ModalProvider: FC = ({ children }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const ToggleIsModalOpen = () => {
        setIsModalOpen((prev) => !prev);
    };

    const modalValue = {
        isModalOpen,
        ToggleIsModalOpen,
    };

    return <ModalContext.Provider value={modalValue}>{children}</ModalContext.Provider>;
};

const useModal = () => {
    return useContext(ModalContext);
};

export { useModal, ModalContext, ModalProvider as default };
