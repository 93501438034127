import React from 'react';
import CardWithDivider from '../../../components/Layout/CardWithDivider';
import { L } from '../../../lib/i18n';
import styled from '@emotion/styled';
import { Button } from '../../../components/Buttons/Button';
import { Loading } from '../../../components/Loading/Loading';
import Table from '../../../components/Table/ReactTable/Table';
import EmptyState from '../../../components/EmptyState/EmptyState';

import { useGetAllProjectsList } from '../../../hooks/useGetProjects';
import { COLUMNSPROJECTS } from './ColumnsProjects';
import useQueryParams from '../../../hooks/useQueryParams';
import { Pagination } from '../../../components/Layout/Pagination';
import { useGetOrganisations } from '../../members/hooks/useGetOrganisations';
import ReactSelect from '../../../components/Dropdown/ReactSelect';
import Label from '../../../components/Forms/Label';
import { style } from '../../../components/Shared/Style';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

const Wrapper = styled.div({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
});
const StyledTable = styled(Table)({
    '& .table': {
        '& .tr': {
            gap: '3rem',
        },
    },
});
const DropDownLabel = styled.div({
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: style.fontSize.milli,
});

const projectListSchema = z.object({
    organisation: z.number(),
});

const PropertiesListPage = () => {
    const { getQueryString, updateQueryString } = useQueryParams();
    const page = getQueryString('page', '');

    const { data: accounts } = useGetOrganisations();
    const accountOptions = accounts?.map((account) => ({
        value: account.id,
        label: (
            <DropDownLabel>
                <span>{account.name}</span>
            </DropDownLabel>
        ),
    }));

    const { control: projectControl, watch } = useForm({
        resolver: zodResolver(projectListSchema),
    });

    const {
        data: projectList,
        status: projectListStatus,
        isFetching,
    } = useGetAllProjectsList(Number(page), watch('organisation'));

    const table = () => {
        if (projectListStatus === 'success' && projectList?.items.length > 0) {
            return <StyledTable columns={COLUMNSPROJECTS} data={projectList.items} />;
        }
        if (isFetching || projectListStatus === 'loading') {
            return <Loading />;
        }
        if (projectListStatus === 'error') {
            return (
                <EmptyState>
                    <EmptyState.Title>{L('no_memberships_title')}</EmptyState.Title>
                    <EmptyState.Description>{L('no_memberships_description')}</EmptyState.Description>
                </EmptyState>
            );
        }
    };

    return (
        <>
            <CardWithDivider
                topArea={
                    <Wrapper>
                        <Label title={`${L('organisation')}*`}>
                            <Controller
                                name="organisation"
                                control={projectControl}
                                render={({ field }) => {
                                    return (
                                        <ReactSelect
                                            options={accountOptions || []}
                                            value={accountOptions?.find((option) => option.value === field.value)}
                                            onChange={(value) => field.onChange(value?.value || 0)}
                                        />
                                    );
                                }}
                            />
                        </Label>
                    </Wrapper>
                }
                mainArea={
                    <>
                        {table()}
                        {projectList && (
                            <div style={{ paddingTop: '1rem' }}>
                                <Pagination
                                    page={projectList}
                                    onChangePage={(newPage) => updateQueryString('page', String(newPage))}
                                />
                            </div>
                        )}
                    </>
                }
            />
        </>
    );
};

export default PropertiesListPage;
