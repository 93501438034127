import ThemeIcons from '../../../components/Icon/ThemedIcons/index';
import React from 'react';

export const GetIconName = (name: string) => {
    console.log('GetIconName', name);
    switch (name) {
        case 'todo':
            return <ThemeIcons.List />;
        case 'document':
            return <ThemeIcons.Document />;
        case 'booking':
            return <ThemeIcons.Booking />;
        case 'binder':
            return <ThemeIcons.Binder />;
        case 'message':
            return <ThemeIcons.Messages />;
        case 'alert':
            return <ThemeIcons.WarningSign />;
        case 'digital_key':
            return <ThemeIcons.DigitalKey />;
        case 'board':
            return <ThemeIcons.Info />;
        case 'calendar':
            return <ThemeIcons.Calendar />;
        case 'addon':
            return <ThemeIcons.Addon />;
        case 'ticket':
            return <ThemeIcons.Ticket />;
        default:
            return <ThemeIcons.Binder />;
    }
};
