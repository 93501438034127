import { trpc } from '../lib/trpc';

const useGetAllProjects = () => {
    return trpc.ProjectsApi.getAllProjects.useQuery();
};

export const useGetAllProjectsList = (page: number, organisationId: number) => {
    return trpc.ProjectsApi.getAllProjectsList.useQuery({ page, organisationId }, { keepPreviousData: true });
};

export default useGetAllProjects;
