import * as React from 'react';
import { Button } from '../../components/Buttons/Button';
import { L } from '../../lib/i18n';
import { style } from '../../components/Shared/Style';
import styled from '@emotion/styled';
import { NavLink } from 'react-router-dom';
import { useState } from 'react';
import { Loading } from '../../components/Loading/Loading';
import { trpc } from '../../lib/trpc';

const Header = styled.div({
    fontWeight: style.fontWeight.bold,
    fontSize: style.fontSize.milli,
    lineHeight: '15px',
    letterSpacing: '0.3px',
    textTransform: 'uppercase',
    color: style.colors.secondary,
    paddingBottom: style.margin.xs,
});

const Background = styled.div({
    width: '100%',
    minHeight: '100vh',
    backgroundSize: 'cover',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
});

const TextField = styled.div({
    paddingBottom: style.margin.ml,
});

const LoginCard = styled.form({
    width: '80%',
});

const StyledNavLink = styled(NavLink)({
    textDecoration: 'none',
    color: style.colors.primary,
    lineHeight: '32px',
    fontSize: style.fontSize.seta,
    fontWeight: style.fontWeight.bold,
});

const LoginInput = styled.input({
    width: '100%',
    background: style.colors.inputBackground,
    border: `1px solid ${style.colors.inputBorder}`,
    height: '42px',
    borderRadius: style.roundCornerSize.small,
    outline: 'none',
    padding: `${style.margin.s} ${style.margin.m}`,
    fontSize: style.fontSize.seta,
    paddingLeft: style.margin.s,
});
const Logo = styled.div({
    paddingBottom: style.margin.xl,
});
const Error = styled.div({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: style.margin.l,
});
const ErrorMessage = styled.strong({
    color: 'red',
});

const ButtonMenu = styled.div({
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    flexDirection: 'column',
    textAlign: 'center',
});

const Card = styled.div({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: style.colors.white,
    padding: '70px 12px 40px 12px',
    border: '1px solid #DFE0EB',
    boxSizing: 'border-box',
    borderRadius: style.roundCornerSize.medium,
    flexDirection: 'column',
    width: '378px',
    height: '450px',
});

// TODO
// ::placeholder {
//     font-size: 14px;
//     line-height: 20px;
//     letter-spacing: 0.3px;
//     color: #4B506D;
//     opacity: 0.4;
// }

// ::-ms-input-placeholder {
//     font-size: 14px;
//     line-height: 20px;
//     letter-spacing: 0.3px;
//     color: #4B506D;
//     opacity: 0.4;
//   }

interface Props {
    children: any;
}

export const LoginPage = (props: Props) => {
    const utils = trpc.useContext();
    const {
        data: user,
        status,
        error,
    } = trpc.AuthApi.getCurrentUser.useQuery(undefined, { retry: 0, keepPreviousData: true });
    const { mutate: login, status: loginStatus } = trpc.AuthApi.login.useMutation();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        login(
            { username, password },
            {
                onSuccess: (data) => {
                    utils.AuthApi.getCurrentUser.setData(undefined, data.user);
                },
            }
        );
    };

    if (user) {
        return <React.Fragment>{props.children}</React.Fragment>;
    }

    if (status === 'loading') {
        return <Loading />;
    }

    return (
        <div>
            <Background>
                <Card>
                    <Logo>
                        <img src={require('../../components/MainMenu/OurLiving.png')} height="40" width="150" />
                    </Logo>
                    <LoginCard onSubmit={handleLogin}>
                        {loginStatus === 'error' &&
                            (error?.data?.code === 'UNAUTHORIZED' ? (
                                <Error>
                                    <ErrorMessage>{L('login_failed')}</ErrorMessage>
                                </Error>
                            ) : (
                                <Error>
                                    <ErrorMessage>{L('login_error')}</ErrorMessage>
                                </Error>
                            ))}
                        <div>
                            <Header>{L('email')}</Header>
                            <TextField>
                                <LoginInput type="text" onChange={(e) => setUsername(e.target.value)} />
                            </TextField>
                        </div>
                        <div>
                            <Header>{L('password')}</Header>
                            <TextField>
                                <LoginInput type="password" onChange={(e) => setPassword(e.target.value)} />
                            </TextField>
                        </div>
                        <ButtonMenu>
                            <Button variant="selectedMenu" type="submit" style={{ width: '100%' }}>
                                {L('login')}
                            </Button>
                        </ButtonMenu>
                    </LoginCard>
                </Card>
            </Background>
        </div>
    );
};
