import styled from '@emotion/styled';
import React from 'react';
import { useParams } from 'react-router';
import { Button } from '../../../components/Buttons/Button';
import { style } from '../../../components/Shared/Style';
import { colors } from '../../../components/Shared/Style/colors';
import { L } from '../../../lib/i18n';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import useChangeEmail from '../hooks/useChangeEmail';
import Input from '../../../components/Forms/Input';
import ErrorAndInputWrapper, { InputWithError } from '../../../components/Forms/ErrorAndInputWrapper';
import LabelRequired from '../../../components/Forms/LabelRequired';
import { useGetMemberById } from '../hooks/useGetUser';
import Label from '../../../components/Forms/Label';

const FormWrapper = styled.div({
    width: '400px',
    placeSelf: 'center',
    backgroundColor: colors.white,
    padding: '2rem',
    minHeight: '300px',
    position: 'relative',
    borderRadius: style.roundCornerSize.small,
});

const ButtonWrapper = styled.div({
    padding: '1rem 0px 0px 0px',
    display: 'flex',
    width: '50%',
    right: '2rem',
    justifyContent: 'space-between',
    position: 'absolute',
    bottom: '1rem',
});

const FormTitle = styled.div({
    fontSize: '1.2rem',
    fontWeight: 'bold',
});

type Props = {
    handleModal: () => void;
};

const changeEmailSchema = z.object({
    email: z.string().email(),
});

type ChangeEmailSchema = z.infer<typeof changeEmailSchema>;

const ChangeEmailForm = ({ handleModal }: Props) => {
    const { id } = useParams<{ id: string }>();

    const { data: user } = useGetMemberById(id);

    const { mutate: changeEmail } = useChangeEmail();

    const {
        handleSubmit,
        register,
        formState: { errors },
    } = useForm<ChangeEmailSchema>({
        resolver: zodResolver(changeEmailSchema),

        defaultValues: {
            email: '',
        },
    });
    const handleChangeEmail = (data: { email: string }) => {
        changeEmail(
            { email: data.email, user_id: +id },
            {
                onSuccess: () => {
                    handleModal();
                    toast.success(L('updated_email_success'));
                },
            }
        );
    };
    return (
        <>
            <FormWrapper>
                <FormTitle>{L('change_email')}</FormTitle>
                <form onSubmit={handleSubmit(handleChangeEmail)}>
                    <Label title={L('current_email')}>
                        <Input disabled={true} value={user?.email} />
                    </Label>
                    <ErrorAndInputWrapper errorMsg={L(errors.email?.message ?? '')}>
                        <LabelRequired title={L('new_email')} required={true}>
                            <InputWithError isError={!!errors.email} {...register('email')} />
                        </LabelRequired>
                    </ErrorAndInputWrapper>
                    <ButtonWrapper>
                        <Button type="button" onClick={() => handleModal()} variant="primary">
                            Avbryt
                        </Button>
                        <Button type={'submit'} variant="filledPrimary">
                            {L('save')}
                        </Button>
                    </ButtonWrapper>
                </form>
            </FormWrapper>
        </>
    );
};

export default ChangeEmailForm;
