import { stringData } from '@app/api/models/i18n';
let translate: any = {};

export async function prepareI18n() {
    translate = stringData;
}

export const L = (key: string) => {
    return translate[key] || key;
};
