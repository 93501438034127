import { CommunityMail } from '@app/api/models/CommunityMail';
import styled from '@emotion/styled';
import { useFormik } from 'formik';
import React from 'react';
import toast from 'react-hot-toast';
import { Button } from '../../../components/Buttons/Button';
import Input from '../../../components/Forms/Input';
import Label from '../../../components/Forms/Label';
import { style } from '../../../components/Shared/Style';
import { L } from '../../../lib/i18n';
import { trpc } from '../../../lib/trpc';

const Forms = styled.form({
    width: '100%',
    display: 'grid',
    alignSelf: 'center',
    paddingTop: style.margin.m,
    paddingBottom: style.margin.l,
    [`@media screen and (min-width: ${650}px)`]: {
        gridTemplateColumns: 'repeat(2, 1fr)',
        gap: `0px clamp(1rem, 2.5vw, 1.5rem)`,
    },
});

const InputContainer = styled.div({
    display: 'flex',
    alignItems: 'center',
    gap: '1ch',
});

const CommunityMailForm = ({ Information }: { Information: CommunityMail }) => {
    const { mutate: save } = trpc.CommunityMailApi.save.useMutation();
    const formik = useFormik({
        initialValues: {
            project_id: Information.project_id,
            ourlivingmail_username: Information.ourlivingmail_username || null,
        },
        onSubmit: (values: CommunityMail) => {
            if (values.ourlivingmail_username)
                save(
                    {
                        ourlivingmail_username: values.ourlivingmail_username,
                        project_id: values.project_id,
                    },
                    {
                        onSuccess: () => {
                            toast.success(L('save_success'));
                        },
                        onError: (e) => {
                            toast.error(e.message ?? L('save_error'));
                        },
                    }
                );
        },
    });

    return (
        <Forms onSubmit={formik.handleSubmit}>
            <Label title={L('mail_name')}>
                <InputContainer>
                    <Input
                        value={formik.values.ourlivingmail_username ?? ''}
                        onChange={formik.handleChange}
                        id="ourlivingmail_username"
                        name="ourlivingmail_username"
                        style={{ width: '35ch' }}
                    />
                    <p>@ourlivingmail.se</p>
                </InputContainer>
            </Label>

            <div />
            <div style={{ marginTop: style.margin.m }}>
                <Button type="submit" variant="selectedMenu">
                    {L('save')}
                </Button>
            </div>
        </Forms>
    );
};

export default CommunityMailForm;
