import React from 'react';
import { PgBossJob } from '@app/api/public/PgBossApi';
import { StyleColumn } from '../../../types/ColumnType';
import dayjs from 'dayjs';
import styled from '@emotion/styled';
import { colors } from '../../../components/Shared/Style/colors';
import RetryJob from './RetryJob';

const DateContainer = styled.div({
    display: 'grid',
    gap: '0.5rem',
});

const State = styled.div(({ stateColor }: { stateColor: string }) => {
    return {
        backgroundColor: stateColor,
        textAlign: 'center',
        width: 'max-content',
        fontWeight: '600',
        padding: '6px 10px 6px 10px',
        borderRadius: '15px',
        color: 'white',
    };
});

export const JobColumns: StyleColumn<PgBossJob>[] = [
    {
        Header: 'Name',
        accessor: 'name',
        Cell: ({ value }) => {
            return <span>{value}</span>;
        },
    },
    {
        Header: 'State',
        accessor: 'state',
        Cell: ({ value }) => {
            return <State stateColor={colors[`job_${value}`]}>{value}</State>;
        },
    },
    {
        Header: 'Created',
        accessor: ({ createdon }) => dayjs(createdon).valueOf(),
        Cell: ({ value }) => {
            return (
                <DateContainer>
                    <span>{dayjs(value).format('DD/MM - YYYY')}</span>
                    <span>{dayjs(value).format('HH:MM:ss')}</span>
                </DateContainer>
            );
        },
    },
    {
        Header: 'Completed',
        accessor: ({ completedon }) => dayjs(completedon).valueOf(),
        Cell: ({ value }) => {
            if (!dayjs(value).isValid()) return null;
            return (
                <DateContainer>
                    <span>{dayjs(value).format('DD/MM - YYYY')}</span>
                    <span>{dayjs(value).format('HH:MM:ss')}</span>
                </DateContainer>
            );
        },
    },
    {
        Header: '',
        accessor: 'id',
        disableSortBy: true,
        Cell: ({ row }) => {
            return (
                <div
                    style={{
                        width: '100%',
                        justifyContent: 'center',
                    }}
                >
                    <RetryJob {...row.original} />
                </div>
            );
        },
    },
];
