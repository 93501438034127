import toast from 'react-hot-toast';
import { trpc } from '../../../../lib/trpc';

const useRunJobInviteMember = () => {
    const utils = trpc.useContext();
    return trpc.PgBossApi.runJob.useMutation({
        onMutate: (variables) => {
            const toastId = toast.loading(`Skapar jobb för att bjuda in medlem: ${variables.data?.name}`);

            return toastId;
        },
        onError: (_error, variables, context) => {
            toast.error(`Kunde inte skapa jobb för att bjuda in medlem: ${variables.data?.name}`, {
                id: context,
            });
        },
        onSuccess: (_data, variables, context) => {
            toast.success(
                `Jobbet för att bjuda in medlem: ${variables.data?.name} är skapat, du hittar jobbet i tabell vyn`,
                {
                    id: context,
                    duration: 8000,
                }
            );
        },
        onSettled: () => {
            utils.PgBossApi.getTasks.invalidate();
            utils.ProjectsApi.getUninvitedMembers.invalidate();
        },
    });
};

export default useRunJobInviteMember;
