import styled from '@emotion/styled';
import React, { FC } from 'react';
import { useModal } from './Context/ModalContext';
import { useTransition, animated, config } from 'react-spring';

const ModalWrapper = styled.div(() => {
    return {
        display: 'grid',
        position: 'fixed',
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
        zIndex: 99,
    };
});
const AnimatedModal = animated(
    styled.div(() => {
        return {
            display: 'grid',
            position: 'fixed',
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
        };
    })
);

const Modal: FC = ({ children }) => {
    const { isModalOpen, ToggleIsModalOpen } = useModal();

    const transitions = useTransition(isModalOpen, {
        from: { opacity: 0, transform: 'translateY(-40px)' },
        enter: { opacity: 1, transform: 'translateY(0px)' },
        leave: { opacity: 0, transform: 'translateY(-40px)' },
        config: {
            duration: 200,
        },
    });
    return transitions(
        (styles, item) =>
            item && (
                <ModalWrapper>
                    <AnimatedModal onClick={() => ToggleIsModalOpen()} style={styles}>
                        {children}
                    </AnimatedModal>
                </ModalWrapper>
            )
    );
};

export default Modal;
