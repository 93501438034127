import ReactDOM from 'react-dom/client';
import React, { useState } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { BrowserRouter, Router } from 'react-router-dom';
import { Routes } from './navigation';
import { prepareI18n, L } from './lib/i18n';
import { history } from './lib/routing';
import { ThemeProvider } from 'emotion-theming';
import { style } from './components/Shared/Style';
import { Toaster } from 'react-hot-toast';
import config from './reactQuery/reactQueryConfig';
import { trpc } from './lib/trpc';
import { css, Global } from '@emotion/core';
import { colors, ThemeType } from './components/Shared/Style/colors';
import { httpBatchLink } from '@trpc/client';
import * as Sentry from '@sentry/react';
import sentryConfig from './sentry/sentryConfig';

const App = () => {
    const sentry_config = sentryConfig();
    Sentry.init({
        dsn: sentry_config.SENTRY_DSN,
        environment: sentry_config.SENTRY_ENVIRONMENT,
        tracesSampleRate: 0.1,
    });

    const [theme, setTheme] = useState<ThemeType>({
        colors: style.colors,
    });

    console.log('theme', theme);

    return (
        <>
            <Global
                styles={css`
                    @import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
                    body {
                        margin: 0;
                        background-color: ${colors.pageBackground};
                        font-family: Lato;
                        box-sizing: border-box;
                        min-width: 320px;
                    }
                    body * {
                        box-sizing: inherit;
                        font-family: inherit;
                        font-size: inherit;
                    }
                    ul {
                        padding: 0;
                        margin: 0;
                    }
                    li {
                        list-style-type: none;
                    }

                    a {
                        text-decoration: none;
                        color: inherit;
                    }
                `}
            />

            <ThemeProvider theme={theme}>
                <div>
                    <Toaster />
                </div>
                <Router history={history}>
                    <Routes />
                </Router>
            </ThemeProvider>
        </>
    );
};

const AppProviders = () => {
    // const [trpcClient] = useState(() =>
    //     trpc.createClient({
    //         url: '/trpc',
    //         fetch(url, options) {
    //             return fetch(url, {
    //                 ...options,
    //                 credentials: 'include',
    //             });
    //         },
    //     })
    // );
    const [queryClient] = useState(() => new QueryClient(config));
    const [trpcClient] = useState(() =>
        trpc.createClient({
            links: [
                httpBatchLink({
                    url: '/trpc',
                    fetch(url, options) {
                        return fetch(url, {
                            ...options,
                            credentials: 'include',
                        });
                    },
                }),
            ],
        })
    );
    return (
        <BrowserRouter>
            <trpc.Provider client={trpcClient} queryClient={queryClient}>
                <QueryClientProvider client={queryClient}>
                    <App />
                    <ReactQueryDevtools initialIsOpen={false} position={'bottom-left'} />
                </QueryClientProvider>
            </trpc.Provider>
        </BrowserRouter>
    );
};

prepareI18n().then(() => {
    const app = document.createElement('div');
    document.title = 'Ourliving - ' + L('administration');
    document.body.appendChild(app);
    const meta = document.createElement('meta');
    meta.name = 'viewport';

    meta.setAttribute('content', 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0');
    document.getElementsByTagName('head')[0].appendChild(meta);

    ReactDOM.createRoot(app!).render(<AppProviders />);
});
