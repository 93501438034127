import React, { FormEvent, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { HelpItem } from '@app/api/models/Help';
import Label from '../../../components/Forms/Label';
import Input from '../../../components/Forms/Input';
import { L } from '../../../lib/i18n';
import { Button } from '../../../components/Buttons/Button';
import useSaveBlogListItem from '../hooks/useSaveBlogListItem';
import { applicationsOptions } from './DropdownOptions';
import { Dropdown } from '../../../components/Dropdown/Dropdown';
import Quill from '../../../components/Quill/Quill';

const Container = styled.div({
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '4px',
    marginBottom: '5rem',
});

const AbortWrapper = styled(Link)({
    textDecoration: 'none',
});

const ButtonWrapper = styled.div({
    display: 'flex',
    gap: '1rem',
    margin: '1rem 0px',
});

const AddBlogView = () => {
    const [projectName, setProjectName] = useState<HelpItem['application']>('community');
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const { mutate: saveBlogListItem, status } = useSaveBlogListItem();
    const [error, setError] = useState({
        title: '',
        description: '',
    });

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!title) {
            setError((prev) => ({ ...prev, title: 'Du måste fylla i en titel' }));
            return;
        }
        if (!description) {
            setError((prev) => ({ ...prev, description: 'Du måste fylla i en beskrivning.' }));
            return;
        }
        if (title && description)
            saveBlogListItem({
                item: {
                    title,
                    description,
                    application: projectName,
                },
            });
    };

    return (
        <Container>
            <form onSubmit={handleSubmit}>
                <Label error={error?.title} title={L('title')}>
                    <Input placeholder="Rubrik..." onChange={(e) => setTitle(e.target.value)} />
                </Label>
                <Label title={L('Applikation')} />
                <Dropdown
                    value={projectName}
                    onChange={(value) => setProjectName(value)}
                    options={applicationsOptions}
                />
                <Label title={L('content')} error={error?.description} />
                <Quill onChange={(value) => setDescription(value)} />
                <ButtonWrapper>
                    {status !== 'loading' && (
                        <AbortWrapper to="/help">
                            <Button variant={'filledPrimary'}>{L('cancel')}</Button>
                        </AbortWrapper>
                    )}
                    <Button variant={'filledPrimary'} type="submit">
                        {status === 'loading' ? L('save') : L('add_item')}
                    </Button>
                </ButtonWrapper>
            </form>
        </Container>
    );
};

export default AddBlogView;
