import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import styled from '@emotion/styled';
import useRunJobInviteMember from './useInviteMember';
import { Button } from '../../../../components/Buttons/Button';
import Label from '../../../../components/Forms/Label';
import { Dropdown } from '../../../../components/Dropdown/Dropdown';
import { L } from '../../../../lib/i18n';
import { trpc } from '../../../../lib/trpc';

const StyledH1 = styled.div({
    fontSize: '1rem',
});

const AddMember = () => {
    const { mutate, isLoading } = useRunJobInviteMember();
    const { data: allProjects } = trpc.ProjectsApi.getAllProjects.useQuery();

    const formik = useFormik({
        initialValues: {
            userId: '',
            projectId: '',
            name: '',
        },
        validationSchema: Yup.object().shape({
            userId: Yup.number().moreThan(0).required(),
            projectId: Yup.number().moreThan(0).required(),
        }),
        onSubmit: (values) => {
            mutate({
                name: 'invite-member',
                data: {
                    userId: +values.userId,
                    projectId: +values.projectId,
                    name: values.name,
                },
            });
        },
    });
    const { data: allUnInvitedUsers } = trpc.ProjectsApi.getUninvitedMembers.useQuery(
        { projectId: +formik.values.projectId },
        {
            enabled: !!formik.values.projectId,
        }
    );
    return (
        <div>
            <StyledH1>{L('invite_member')}</StyledH1>
            <form onSubmit={formik.handleSubmit}>
                <Label
                    title={L('project')}
                    style={{ marginBottom: '2rem' }}
                    error={formik.touched.projectId ? formik.errors.projectId : undefined}
                >
                    <Dropdown
                        value={formik.values.projectId}
                        onChange={(value) => {
                            formik.setFieldValue('projectId', value);
                        }}
                        placeholder={L('select_project')}
                        options={
                            allProjects?.map((project) => ({
                                label: `${project.name} | Id: ${project.id}`,
                                value: project.id,
                            })) || []
                        }
                        onBlur={formik.handleBlur}
                    />
                </Label>

                {formik.values.projectId && (
                    <>
                        <Label
                            title={L('member')}
                            style={{ marginBottom: '2rem' }}
                            error={formik.touched.userId ? formik.errors.userId : undefined}
                        >
                            <Dropdown
                                value={{
                                    id: formik.values.userId,
                                    name: formik.values.name,
                                }}
                                onChange={(value) => {
                                    formik.setFieldValue('userId', value.id);
                                    formik.setFieldValue('name', value.name);
                                }}
                                placeholder={L('select_member')}
                                options={
                                    allUnInvitedUsers?.map((user) => ({
                                        label: (
                                            <div
                                                style={{
                                                    display: 'grid',
                                                    gap: '2rem',
                                                    gridTemplateColumns: '1fr 1fr 1fr',
                                                }}
                                            >
                                                <span>{user.name}</span>
                                                <span>{user.email}</span>
                                                <span>Id: {user.id}</span>
                                            </div>
                                        ),
                                        value: { id: user.id, name: user.name },
                                    })) || []
                                }
                                onBlur={formik.handleBlur}
                            />
                        </Label>
                        <Button type="submit" disabled={isLoading} variant="primary">
                            {L('run_job')}
                        </Button>
                    </>
                )}
            </form>
        </div>
    );
};

export default AddMember;
