import React from 'react';
import { FC } from 'react';
import styled from '../../../lib/styled';
import { colors } from '../../Shared/Style/colors';
import Icon from '../calendar-date-2.svg';

const ThemedCalendar = styled(Icon)(() => {
    return {
        height: '24px',
        width: '24px',

        '& g': {
            stroke: colors.icons,
        },
        '& path': {
            stroke: colors.icons,
        },
        '& polyline': {
            stroke: colors.icons,
        },
        '& line': {
            stroke: colors.icons,
        },
    };
});
const Calendar: FC = ({ ...rest }) => {
    return <ThemedCalendar {...rest} />;
};

export default Calendar;
