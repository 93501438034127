import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { LoginPage } from './areas/login/LoginPage';
import PrivateRoutes from './PrivateRoutes';

export const Routes = () => (
    <Switch>
        <LoginPage>
            <PrivateRoutes />
        </LoginPage>
        <Route>
            <div>404 not found</div>
        </Route>
    </Switch>
);
