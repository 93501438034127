import React from 'react';
import { IPageOf } from '@app/api/lib/db';
import styled from 'styled-components';
import { ArrowLeft, ArrowRight } from 'react-feather';
import { colors } from '../Shared/Style/colors';
import { style } from '../Shared/Style';
import { L } from '../../lib/i18n';

interface PagerProps {
    page: IPageOf<any>;
    onChangePage(newPage: number): void;
}

const PaginationContainer = styled.div({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '0.5rem',
});

const DirectionsMarker = styled.button({
    outline: `1px solid ${colors.primary}`,
    border: 'none',
    backgroundColor: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0.5rem 1rem',
    textAlign: 'center',
    lineHeight: '16px',
    borderRadius: '6px',
    boxSizing: 'border-box',
    fill: colors.primary,
    [':hover']: {
        outline: `2px solid ${colors.primary}`,
        cursor: 'pointer',
    },
    [':disabled']: {
        outline: `1px solid ${colors.divider}`,
        fill: colors.divider,
        cursor: 'inherit',
    },
});

const StyledSpan = styled.span({
    fontSize: style.fontSize.milli,
    fontWeight: style.fontWeight.bold,
    textTransform: 'uppercase',
    color: style.colors.secondary,
});

const StyledLeftArrow = styled(ArrowLeft)({
    alignSelf: 'center',
    gridArea: 'icon',
    height: '16px',
    width: '10px',
});

const StyledRightArrow = styled(ArrowRight)({
    alignSelf: 'center',
    gridArea: 'icon',
    height: '16px',
    width: '10px',
});

export function Pagination(props: PagerProps) {
    if (props.page.pages < 1) return null;
    const canGoBack = props.page.currentPage > 1;
    const canGoForwards = props.page.currentPage < props.page.pages;
    const goBack = () => props.onChangePage(props.page.currentPage - 1);
    const goForward = () => props.onChangePage(props.page.currentPage + 1);

    return (
        <PaginationContainer>
            <DirectionsMarker disabled={!canGoBack} onClick={goBack}>
                <span>
                    <StyledLeftArrow />
                </span>
            </DirectionsMarker>
            <StyledSpan>{`${L('page')} ${props.page.currentPage} ${L('of')} ${props.page.pages}`}</StyledSpan>
            <DirectionsMarker disabled={!canGoForwards} onClick={goForward}>
                <span>
                    <StyledRightArrow />
                </span>
            </DirectionsMarker>
        </PaginationContainer>
    );
}
