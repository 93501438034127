import { Route } from 'react-router-dom';
import React from 'react';
import { L } from '../../lib/i18n';
import { PropertyPage } from './PropertyPage';

export const routes = [
    <Route key="1" path="/properties/:id" component={PropertyPage} />,
    <Route key="0" exact path="/properties" component={PropertyPage} />,
];

export const navigation = [{ name: L('properties'), link: '/properties', icon: 'Project' }];
