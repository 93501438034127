import React from 'react';
import { Column, useFlexLayout, useGlobalFilter, useRowSelect, useSortBy, useTable } from 'react-table';
import Checkbox from '../../../components/Table/checkbox/Checkbox';

export type Props<T extends Record<string, unknown>> = {
    columns: Column<T>[];
    data: T[];
};

const useTableCheckbox = <T extends Record<string, unknown>>({ columns, data }: Props<T>) => {
    const dataMemo = React.useMemo(() => (data ? data : []), [data]);
    const columnsMemo = React.useMemo<readonly Column<T>[]>(() => [...columns], [data, columns]);

    return useTable<T>(
        {
            columns: columnsMemo,
            data: dataMemo,
        },
        useGlobalFilter,
        useSortBy,
        useFlexLayout,
        useRowSelect,
        (hooks) => {
            hooks.visibleColumns.push((columns) => {
                return [
                    {
                        id: 'selection',
                        Header: ({ getToggleAllRowsSelectedProps }) => (
                            <Checkbox {...getToggleAllRowsSelectedProps()} />
                        ),
                        Cell: ({ row }) => <Checkbox {...row.getToggleRowSelectedProps()} />,
                        disableGlobalFilter: true,
                    },
                    ...columns,
                ];
            });
        }
    );
};

export default useTableCheckbox;
