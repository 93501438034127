export const colors = {
    white: 'white',
    black: '#252733',
    black80: '#AFAFAF',
    black50: '#C5C7CD',
    black30: '#F0F1F7',
    black10: '#FCFDFE',
    background: '#F5F5F5',
    headerBackground: '#5d5d5d',
    primary: '#86AE43',
    primary50: '#C2D6A1',
    primary25: '#E4EDD6',
    alertPrimary: '#cfe2ff',
    secondary: '#9FA2B4',
    notofication: '#F12B2C',
    newcount: '#2EB67D',
    pageBackground: '#F7F8FC',
    errors: 'red',
    inputBackground: '#FCFDFE',
    inputBorder: '#F0F1F7',
    icons: '#000000',
    statusDone: '#29CC97',
    statusWarning: '#FEC400',
    statusError: '#F12B2C',
    deleteButton: '#dc3545',
    deleteButtonHover: '#ad2a36',
    plusButton: '#357adb',
    textColor1: '#9DA3B4',
    inputHoverBackground: '#E3F0FE',
    maintenance_completed_status: '#ABB8C3',
    textColor2: '#444444',
    tabActive: '#86AE43',
    tabInactive: '#9DA3B4',
    avatarBackground: '#76A4AE',
    divider: '#DFE0EB',

    job_completed: '#D9E5B4',
    job_failed: '#EEA4A7',
    job_created: '#EAD7A2',
};

export type Colors = keyof typeof colors;

export type ThemeType = {
    colors: {
        [Key in Colors]: string;
    };
};
